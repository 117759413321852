import React from 'react';
import { Button, Heading } from 'mondrian-react';
import ModalService from '../../../../../services/ModalService';

import styles from './ApiErrorComponent.module.scss';
import JaSouClienteContext from '../../../../../contexts/jornada-ja-sou-cliente';
import CroService from '../../../../../services/CroService';

const ApiErrorComponent: React.FC = () => {
  const context = React.useContext(JaSouClienteContext);

  const handleFechar = (): void => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'fechar:tela-ops-api-error'
    });
    ModalService.close();
  }

  const handleVoltar = () => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'voltar:home-modal'
    });
    context.navigateTo('sendCode')
  }

  return (
    <div id="api-error-container" className={styles.container}>
      <button id="button-fechar-modal"
        className={styles.buttonFecharModal}
        onClick={handleFechar}>
        <span className="mdn-Icon-circulo-fechar mdn-Icon--md"></span>
      </button>

      <Heading className={styles.modalHeading} md>Ops!</Heading>

      <div className={`mdn-Row ${styles.mdnRow}`}>
        <div className={`mdn-Col-xs-12 mdn-Col-sm-6 ${styles.illustrationPart}`}
          id='illustration-part' />
        <div className={`mdn-Col-xs-12 mdn-Col-sm-6 ${styles.messagePart}`}
          id='message-part'>
          <Heading xs className={styles.apiErrorMessage}>
            No momento não conseguimos consultar as ofertas para o seu número.
          </Heading>
          <Button type='button' className={styles.voltarBtn}
            secondary
            onClick={handleVoltar}>
            Voltar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ApiErrorComponent;
