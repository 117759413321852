/**
 * 
 * @param key - The key of the item to get.
 * @returns A set of functions to interact with sessionStorage.
 * @example
 * const { getItem, setItem } = useSessionStorage('key')
 * 
 * setItem('value')
 * console.log(getItem())
 */
const useSessionStorage = (key: string) => {

  /**
   * Gets the value of an item.
   * @returns The value of the item.
   */
  const get = () => sessionStorage.getItem(key) || null

  /**
   * Sets the value of an item.
   * @param value - The value of the item to set.
   */
  const set = (value: any) => sessionStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value))

  /**
   * Deletes an item.
   */
  const destroy = () => sessionStorage.removeItem(key)

  return {
    getItem: get,
    setItem: set,
    delItem: destroy,
  }
}

export default useSessionStorage