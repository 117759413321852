import { ModalRefInterface } from "../types/ModalRef"
import { ModalSettings } from "../types/ModalSettings"

class ModalService {
  modalWrapperRef: ModalRefInterface

  constructor() { return }

  registerModal(ref: ModalRefInterface) { this.modalWrapperRef = ref }
  open(params: ModalSettings) { this.modalWrapperRef.open(params) }
  close() { this.modalWrapperRef.close() }
}

export default new ModalService()
