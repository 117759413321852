import { atom } from "jotai"

export interface IInternalControl {
  allowToOpenOfferByNetwork: boolean
}

const defaultState = atom<IInternalControl>({
  allowToOpenOfferByNetwork: false,
} as IInternalControl)

export const internalControlAtom = atom(
  (get) => get(defaultState),
  (get, set, update: Partial<IInternalControl>) => {
    set(defaultState, { ...get(defaultState), ...update })
  }
)
