

const regex = /^([a-zA-ZÀ-ÖØ-öø-ÿ\s'-]{2,}\s[a-zA-ZÀ-ÖØ-öø-ÿ\s'-]{1,})/

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: {
    message: "O campo deve conter nome e sobrenome.",
    valid: false,
    regex
  }
}
export const name = (value) => {
  if (!value) return responses.empty
  const validation = regex.test(value)
  if (!validation) return responses.invalid
  return responses.valid
}
