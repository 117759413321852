import React, { ChangeEvent, FocusEvent, SyntheticEvent } from 'react';
import { Button, Heading, Input, Text } from 'mondrian-react';
import ReactLoading from 'react-loading';
import { useRouter } from 'next/router';
import { AxiosError } from 'axios';

import JaSouClienteContext from '../../../../../contexts/jornada-ja-sou-cliente';

import { SessionStorageKeys } from '../../../../../types/SessionStorageKeys';
import { FormControl } from '../send-code/form/types/FormControl';
import { ModalSettings } from '../../../../../types/ModalSettings';

import useMobileInfo from '../../../../../hooks/useMobileInfo';

import Utils from '../../../../../services/UtilsService';
import ModalService from '../../../../../services/ModalService';
import MobileInfoService from '../../../../../services/MobileInfoService';
import BucketS3Service from '../../../../../services/BucketS3Service';
import CroService from '../../../../../services/CroService';
import ViabilityService from '../../../../../services/ViabilityService';
import StoryBlockService from '../../../../../services/StoryBlokService';
import { DeParaItem } from '../../../../../types/JornadaJaSouCliente/DeParaItem';

import BannerPromocional from '../../BannerPromocional/BannerPromocional';

import styles from './MobileClientValidationComponent.module.scss';

interface FormState {
  controls: { validationCode: FormControl },
  valid: boolean,
  loading: boolean
}

const MobileClientValidationComponent: React.FC = () => {
  const context = React.useContext(JaSouClienteContext);

  const [seconds, setSeconds] = React.useState(30);
  const [loading, setLoading] = React.useState(false);
  const [dePara, setDePara] = React.useState<DeParaItem[]>([]);
  const router = useRouter();

  const {
    consultarMobileInfo,
    isLoading: isMobileInfoLoading,
    error: mobileInfoError,
    mobileInfo,
  } = useMobileInfo();

  const [formState, setFormState] = React.useState<FormState>({
    controls: {
      validationCode: {
        value: '',
        error: undefined,
        touched: false,
        valid: undefined,
        validating: false,
        disabled: false,
        required: true,
        focused: false,
        loading: false
      }
    },
    valid: false,
    loading: false,
  });

  const celular = Utils.mascararNumeroCelular(sessionStorage.getItem(SessionStorageKeys.CELULAR_JA_SOU_CLIENTE));

  const handleFormSubmit = async (e: SyntheticEvent<Element, Event>): Promise<void> => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'validar-codigo'
    });
    const cpf = sessionStorage.getItem(SessionStorageKeys.CPF_JA_SOU_CLIENTE);
    const msisdn = sessionStorage.getItem(SessionStorageKeys.CELULAR_JA_SOU_CLIENTE);
    const token = sessionStorage.getItem(SessionStorageKeys.SMS_ECARE_TOKEN_JA_SOU_CLIENTE);
    const code = formState.controls.validationCode.value;
    const { status } = await consultarMobileInfo(cpf, msisdn, code, token);
    if (status === 'success') {
      sessionStorage.setItem('cliente-claro-movel', 'true');
      sessionStorage.setItem('client_already_authenticated', 'true')
      sessionStorage.setItem('claro-movel-promo-applied', 'true')
    }
  };

  const handleOnFocus = (): void => {
    setFormState((prevState) => validateFormState({
      ...prevState,
      controls: {
        ...prevState.controls,
        validationCode: {
          ...prevState.controls.validationCode,
          focused: true,
          touched: true,
        },
      },
      valid: false,
    }));
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value.length === 6) {
      const result = validarValidationCode(value);
      setFormState((prevState) => validateFormState({
        ...prevState,
        controls: {
          ...prevState.controls,
          validationCode: {
            ...prevState.controls.validationCode,
            value: value,
            valid: result.valid,
            error: result.valid ? undefined : result.errorMessage
          }
        }
      }));
    } else {
      setFormState((prevState) => validateFormState({
        ...prevState,
        controls: {
          ...prevState.controls,
          validationCode: {
            ...prevState.controls.validationCode,
            value: value,
          }
        }
      }));
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>): void => {
    const value = e.target.value;
    const result = validarValidationCode(value);
    setFormState((prevState) => validateFormState({
      ...prevState,
      controls: {
        ...prevState.controls,
        validationCode: {
          ...prevState.controls.validationCode,
          valid: result.valid,
          error: result.valid ? undefined : result.errorMessage,
        }
      }
    }));
  }

  const handleEnviarNovamente = async (e?: SyntheticEvent<Element, Event>): Promise<void> => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'enviar-codigo-novamente'
    });
    try {
      setLoading(true);
      const msisdn = sessionStorage.getItem(SessionStorageKeys.CELULAR_JA_SOU_CLIENTE);
      const response = await MobileInfoService.sendSMS(msisdn);
      const token = response.data.data.token;
      sessionStorage.setItem(SessionStorageKeys.SMS_ECARE_TOKEN_JA_SOU_CLIENTE, token);
      setSeconds(30);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError500();
    }
  }

  /**
   * Validates the state of a form by checking if all required controls are valid.
   * @param formState - The current state of the form.
   * @returns The updated form state with a valid property indicating if the form is valid or not.
   */
  const validateFormState = (formState: FormState): FormState => {
    const controllerValues = Object.values(formState.controls);

    for (let i = 0; i < controllerValues.length; i++) {
      if (!controllerValues[i].valid && controllerValues[i].required) {
        return { ...formState, valid: false };
      }
    }

    return { ...formState, valid: true };
  }

  const validarValidationCode = (input: string): { valid: boolean, errorMessage?: string } => {
    const digitos = input.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    if (digitos.length === 6) {
      return { valid: true, errorMessage: undefined };
    }
    if (digitos.length < 6) {
      return { valid: false, errorMessage: "O código deve ter pelo menos 6 dígitos." };
    }
    if (digitos.length > 6) {
      return { valid: false, errorMessage: "O código deve ter no máximo 6 dígitos." };
    }
  };

  const showError500 = React.useCallback((): void => {
    ModalService.close();
    router.push('/error/500');
  }, [router])

  const handleVoltar = () => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'voltar:home-modal'
    });
    context.navigateTo('sendCode')
  }

  const fecharModal = (): void => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'fechar:validacao-celular'
    });
    sessionStorage.removeItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE);
    ModalService.close();
  }

  React.useEffect(() => {
    if (mobileInfo) {
      sessionStorage.setItem(
        SessionStorageKeys.MOBILE_INFO_RESPONSE,
        JSON.stringify(mobileInfo.data)
      );
      const { situation, customerType, customerName } = mobileInfo.data;
      const isClienteMovel = situation === 'ATIVO' && customerType === 'owner';

      sessionStorage.setItem(SessionStorageKeys.CUSTOMER_NAME, customerName);

      if (!isClienteMovel) {
        context.navigateTo('notMobileCustomer');
        return;
      } else {
        sessionStorage.setItem(
          SessionStorageKeys.MOBILE_CUSTOMER_JA_SOU_CLIENTE,
          isClienteMovel.toString()
        );
      }

      const { dependents, planType, promotionDiscountPlanValue, planId } = mobileInfo.data;

      const viabilityTechnologies = ViabilityService.getTechnologies();
      const isGpon = !!viabilityTechnologies.find(tech => tech.gpon && tech.name === 'Cable')
      const isHFC = !!viabilityTechnologies.find(tech => !tech.gpon && tech.name === 'Cable')
      const technology: string = isGpon ? 'gpon' : isHFC ? 'hfc' : 'dth'

      const hasDependents = dependents.length > 0;
      const hasPlanoComDesconto = !!promotionDiscountPlanValue;

      const isElegivelParaUpgrade =
        !hasDependents &&
        !hasPlanoComDesconto;

      const cep = sessionStorage.getItem(SessionStorageKeys.CEP_JA_SOU_CLIENTE);
      const numero = sessionStorage.getItem(SessionStorageKeys.NUMERO_JA_SOU_CLIENTE);

      let itemDePara;

      if (isElegivelParaUpgrade) {
        itemDePara = dePara.find(item => item.de === planId);

        if (itemDePara) {
          sessionStorage.setItem(
            SessionStorageKeys.DE_PARA_JA_SOU_CLIENTE,
            JSON.stringify(itemDePara)
          );

        }
      }

      const getBannerInfo = async () => {
        const {data} = await StoryBlockService.getBannersPromotionalClienteMovelInfo(technology, planType);
        if (data?.stories?.length > 0) {
          let contents: any[] = []
          data.stories.forEach(story => {
            const { content, slug } = story;
            const storyInfo = {
                ...content,
                slug
            };
            contents.push(storyInfo)
          });

          if (contents) {
            const screenWidth = window.innerWidth;
            const size = screenWidth >= 768 ? "lg" : "sm";

            const componentProps_ : any = {
              storyBlok: contents,
              cep,
              numero,
              tech: technology
            }
            if (isElegivelParaUpgrade && !!itemDePara) {
              componentProps_.celularId = itemDePara.para;
            }

            ModalService.open({
              component: BannerPromocional,
              componentProps: componentProps_,
              bannerMode: true,
              bannerPromocional: true,
              size: size,
              disableOverflowY: true,
              disableOverflowX: true,
            } as ModalSettings);
          }
        } else {
          sessionStorage.setItem('originId', 'cliente_movel-cobertura')
          ModalService.close();
          window.location.href = `${process.env.prospectUrl}/monte-sua-combinacao?cep=${cep}&number=${numero}${!!itemDePara ? '&celularId=' + itemDePara.para : ''}`
        }
      }

      getBannerInfo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, dePara, mobileInfo]);

  /**
   * useEffect hook that sets up a timer to decrement seconds state by 1 every second.
   * If seconds state reaches 0, the timer is cleared.
   * @returns a function that clears the timer when the component unmounts or when seconds state changes
   */
  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    if (seconds === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  /**
   * useEffect hook that handles error responses from Axios requests related to mobile information API.
   * @param {Function} handleErrorResponse - Function that handles the error response.
   */
  React.useEffect(() => {
    const handleErrorResponse = (error: AxiosError): void => {
      const err = error.response.data;
      const { statusCode, error: { code: errorCode } } = err;
      if (statusCode === 400 && (errorCode === '400' || errorCode === '400-002')) { // Token Expirado
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'token-expirado'
        });
        setFormState(prevState => ({
          ...prevState,
          controls: {
            ...prevState.controls,
            validationCode: {
              ...prevState.controls.validationCode,
              valid: false,
              error: 'Token Expirado'
            }
          }
        }));
        return;
      } else if (statusCode === 404 && errorCode === '404') { // Token Inválido
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'token-invalido'
        });
        showError500();
      } else if (statusCode === 406 && errorCode === '406') { // Code inválido
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'code-invalido'
        });
        setFormState(prevState => ({
          ...prevState,
          controls: {
            ...prevState.controls,
            validationCode: {
              ...prevState.controls.validationCode,
              valid: false,
              error: 'Código inválido'
            }
          }
        }));
        return;
      } else if ( // Não é cliente Claro Movel
        (statusCode === 422 && errorCode === '422') ||
        (statusCode === 404 && errorCode === '404-001')
      ) {
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'nao-cliente-claro:movel'
        });
        context.navigateTo('notMobileCustomer');
      } else if (errorCode === '400-004') { // tem 2 celular com o mesmo cpf
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'mais-de-um-celular-no-cpf'
        });
        context.navigateTo('notMobileCustomer');
      } else if (errorCode === '400-005') { // erro na validação do sms
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'erro-validacao-sms'
        });
        showError500();
      } else if (errorCode === '400-006') { // número de celular não é o principal
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'celular-nao-principal'
        });
        context.navigateTo('notMobileCustomer');
      } else { // Qualquer outro erro
        CroService.postDataLayer({
          event: 'event',
          eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
          eventAction: 'catch:api-error',
          eventLabel: 'default'
        });
        showError500();
      }
    }

    if (mobileInfoError) {
      handleErrorResponse(mobileInfoError);
    }
  }, [context, mobileInfoError, showError500]);

  React.useEffect(() => {
    const fetchDeParaIdentificacaoMovel = async () => {
      const response = await BucketS3Service.buscarDeParaIdentificacaoMovel();
      setDePara(response);
    }

    fetchDeParaIdentificacaoMovel();
  }, []);

  return (
    <div id="mobile-client-validation-container" className={styles.container}>
      <button id="button-fechar-modal" className={styles.buttonFecharModal} onClick={fecharModal}>
        <span className="mdn-Icon-circulo-fechar mdn-Icon--md"></span>
      </button>
      <Heading className={styles.modalHeading} md>
        Vamos verificar o seu número de celular
      </Heading>
      <Text body className={styles.subtitle}>
        Digite abaixo o código de verificação que foi enviado por SMS para <strong>{celular}</strong>
      </Text>
      <form className='mdn-Row' onSubmit={handleFormSubmit}>
        <div className="mdn-Col-xs-12 mdn-Col-sm-12 mdn-Col-md-6">
          <Input
            id="validationCode"
            name="validationCode"
            number
            required={formState.controls.validationCode.required}
            hasError={formState.controls.validationCode.valid === false}
            errorMessage={formState.controls.validationCode.error}
            value={formState.controls.validationCode.value}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onChange={handleOnChange}
          >
            Código de verificação
          </Input>
        </div>
        <div className="mdn-Col-xs-12 mdn-Col-sm-12 mdn-Col-md-6">
          <Button type='submit' primary disabled={!formState.valid || formState.loading} lg>
            {formState.loading || isMobileInfoLoading
              ? <ReactLoading
                type="spin"
                color="#FFF"
                height={20}
                width={20}
              /> : 'Validar código'
            }
          </Button>
        </div>
      </form>
      <section id="enviar-codigo-novamente" className={styles.reenviarCodigo}>
        <Heading xs>
          Não recebeu seu código?
        </Heading>
        {seconds > 0 ? (
          <Text body>
            Você pode pedir novamente em {seconds} {seconds === 1 ? 'segundo' : 'segundos'}...
          </Text>
        ) : (
          <Button type='submit' secondary onClick={handleEnviarNovamente} disabled={loading}>
            {loading
              ? <ReactLoading
                type="spin"
                color="#000"
                height={20}
                width={20}
              /> : 'Enviar código novamente'
            }
          </Button>
        )}
      </section>
      <Button type='button' className={styles.voltarBtn} secondary
        onClick={handleVoltar}>
        Voltar
      </Button>
    </div>
  );
}

export default MobileClientValidationComponent;

