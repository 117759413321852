import { IStoryblokState } from "../../store"

import { resolvePorteira, resolveBlackFriday, resolveAffiliateIds, resolveClaroMobileCustomer, resolveExclusiveCheckout, } from "./stories/general-rules"

export const storyblokParse = (
  story: any
): IStoryblokState["data"] => {
  return {
    generalRules : {
      exclusiveCheckout: resolveExclusiveCheckout(story),
      porteira: resolvePorteira(story),
      blackFriday: resolveBlackFriday(story),
      affiliateIds: resolveAffiliateIds(story),
      claroMobileCustomer: resolveClaroMobileCustomer(story)
    }
  }
}
