const regex = /\D/g

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: { message: "Data inválida.", valid: false, regex },
  minAge: { message: "Sua idade deve estar entre 18 e 90 anos.", valid: false, regex },
  maxAge: { message: "Sua idade deve estar entre 18 e 90 anos.", valid: false, regex }
}

export const birthdate = (value) => {
  const birthdate = value.replace(regex, "")
  const monthsWith29Days = [2]
  const monthsWith30Days = [4, 6, 9, 11]
  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12]

  if (!value) return responses.empty // se o campo estiver vazio
  if (birthdate.length !== 8) return responses.invalid // se o campo não tiver 8 dígitos

  const [day, month, year] = value.split("/").map((v) => +v)
  const currDate = new Date()
  const maxYear = currDate.getFullYear() - 18
  const minYear = currDate.getFullYear() - 90

  if (month > 12 || month < 1) return responses.invalid // se o mês for maior que 12 ou menor que 1
  if (day < 1) return responses.invalid // se o dia for menor que 1
  if (monthsWith30Days.includes(month) && day > 30) return responses.invalid // se o mês tiver 30 dias e o dia for maior que 30
  if (monthsWith31Days.includes(month) && day > 31) return responses.invalid // se o mês tiver 31 dias e o dia for maior que 31
  if (monthsWith29Days.includes(month) && day > 29) return responses.invalid // se o mês for fevereiro e o dia for maior que 29

  if (year < minYear) return responses.maxAge // se a diferença entre a data e a data atual for maior que 90 anos
  if (year === minYear && month < currDate.getMonth() + 1) { return responses.maxAge } // se o mês for menor que o mês atual
  if (year === minYear && month === currDate.getMonth() + 1 && day <= currDate.getDate()) { return responses.maxAge } // se o dia for menor ou igual ao dia atual

  if (year > maxYear) return responses.minAge // se a diferença entre a data e a data atual for menor que 18 anos
  if (year === maxYear && month > currDate.getMonth() + 1) return responses.minAge // se o mês for maior que o mês atual
  if (year === maxYear && month === currDate.getMonth() + 1 && day > currDate.getDate()) return responses.minAge // se o dia for maior que o dia atual

  return responses.valid
}
