import React, { CSSProperties, FC } from 'react'

interface IDrawerProps {
  children?: React.ReactNode
  open?: boolean
  onClick?: () => void
  customStyles?: {
    overflowY?: CSSProperties['overflowY']
    maxWidth?: CSSProperties['maxWidth']
  }
}

import styles from './styles.module.scss'

const Drawer: FC<IDrawerProps> = ({ children, open, onClick, customStyles }) => {

  const backdrop = `${styles.Backdrop} ${open === undefined ? styles.BackdropHidden : open ? styles.BackdropOpen : styles.BackdropClose}`
  const drawer = `${styles.Drawer} ${open === undefined ? styles.DrawerHidden : open ? styles.DrawerOpen : styles.DrawerClose}`

  return (
    <>
      <div
        style={{
          overflowY: customStyles?.overflowY,
          maxWidth: customStyles?.maxWidth
        }}
        className={drawer}
      >
        {children}
      </div>
      <div onClick={onClick} className={backdrop} />
    </>
  )
}

export default Drawer
