interface IBlackFridayResponse {
  activated: boolean
  component: string
}

export const resolveBlackFriday = (
  story: any
): IBlackFridayResponse => {
  return {
    activated: story.data.stories[0].content?.blackfriday[0]?.activated,
    component: story.data.stories[0].content?.blackfriday[0]?.component
  }
}
