/**
 * Enumeração SessionStorageKeys
 *
 * Esta enumeração define as chaves utilizadas para acessar os dados armazenados na sessionStorage.
 * As chaves são usadas para identificar os valores salvos e recuperar as informações relevantes.
 */

export enum SessionStorageKeys {
  CPF_JA_SOU_CLIENTE = 'cpf@JaSouCliente',
  CELULAR_JA_SOU_CLIENTE = 'celular@JaSouCliente',
  CEP_JA_SOU_CLIENTE = 'cep@JaSouCliente',
  NUMERO_JA_SOU_CLIENTE = 'numero@JaSouCliente',
  CEP_SEM_NUMERO_JA_SOU_CLIENTE = 'cep-sem-numero@JaSouCliente',
  SMS_ECARE_TOKEN_JA_SOU_CLIENTE = 'sms-ecare-token@JaSouCliente',
  OUTRO_ENDERECO_JA_SOU_CLIENTE = 'outro-endereco@JaSouCliente',
  RESIDENTIAL_CUSTOMER_JA_SOU_CLIENTE = 'residential-customer@JaSouCliente',
  MOBILE_CUSTOMER_JA_SOU_CLIENTE = 'cliente-claro-movel',
  DE_PARA_JA_SOU_CLIENTE = 'cliente-movel-de-para-mobile-plan',
  MOBILE_INFO_RESPONSE = 'customer-mobile-info-api-return',
  CUSTOMER_NAME = 'customerName'
}
