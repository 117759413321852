import { CheckboxControl, FormControl } from "./FormControl";

export enum FormActionTypes {
  UPDATE_CONTROL = 'UPDATE_CONTROL',
  UPDATE_CHECKBOX_CONTROL = 'UPDATE_CHECKBOX_CONTROL',
  ENABLE_DISABLED_FIELDS = 'ENABLE_DISABLED_FIELDS',
  DISABLE_ALL_FIELDS = 'DISABLE_ALL_FIELDS',
}

export type UpdateControlPayload = {
  control: {
    name: string,
    state: Partial<FormControl>
  }
}

export type UpdateCheckboxControlPayload = {
  control: {
    name: string,
    state: Partial<CheckboxControl>
  }
}

export type ActionPayload = | UpdateControlPayload | UpdateCheckboxControlPayload;

export type FormAction =
  | { type: FormActionTypes.UPDATE_CONTROL, payload: ActionPayload }
  | { type: FormActionTypes.UPDATE_CHECKBOX_CONTROL, payload: ActionPayload }
  | { type: FormActionTypes.ENABLE_DISABLED_FIELDS }
  | { type: FormActionTypes.DISABLE_ALL_FIELDS };



