import React, { ChangeEvent, FocusEvent, useRef } from 'react';
import { Input } from 'mondrian-react';

import FormContext from '../context/FormContext';
import { FormActionTypes } from '../types/FormReducer';
import { SessionStorageKeys } from '../../../../../../../types/SessionStorageKeys';

import CroService from '../../../../../../../services/CroService';
import Utils from '../../../../../../../services/UtilsService';

const CelularControl: React.FC = () => {
  const { formState, dispatchFormState } = React.useContext(FormContext);
  const inputRef = useRef(null);

  const CONTROL_NAME = 'celular';
  const MIN_CELLPHONE_LENGTH = 11;

  const handleOnFocus = (): void => {
    // Atualiza o estado do controle do formulário indicando que o campo foi tocado e está com foco
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: 'celular',
          state: {
            touched: true, // Define o estado como tocado
            focused: true, // Define o estado como com foco
          }
        }
      }
    });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Limpa o valor do número de celular utilizando o serviço Utils
    const value = Utils.limparCelular(e.target.value);
    // Salva o valor do celular na sessionStorage
    saveToSessionStorage(value);

    // Atualiza o estado do controle do formulário com o novo valor do celular
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            value, // Define o novo valor do celular
          }
        }
      }
    });
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>): void => {
    // Limpa o valor do número de celular utilizando o serviço Utils
    const value = Utils.limparCelular(e.target.value);
    // Valida o número do celular utilizando o serviço Utils
    const result = Utils.validarCelular(value);

    // Atualiza o estado do controle do formulário com base no resultado da validação e outras propriedades
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: 'celular',
          state: {
            valid: result.valid, // Define se o celular é válido ou não
            error: !result.valid ? result.errorMessage : undefined, // Define a mensagem de erro, caso o celular seja inválido
            focused: false, // Define o estado de foco como false, indicando que o campo perdeu o foco
          }
        }
      }
    });

    // Verifica se o valor do celular possui algum caractere
    if (value.length > 0) {
      // Dispara o evento de rastreamento para o serviço CroService
      CroService.postDataLayer({
        event: 'event',
        eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
        eventAction: 'interacao:campo',
        eventLabel: 'preencheu:numero-telefone'
      });
    }
  }

  const saveToSessionStorage = (value: string) => {
    const key = SessionStorageKeys.CELULAR_JA_SOU_CLIENTE;
    window.sessionStorage.setItem(key, value);
    window.sessionStorage.setItem('claro-movel-phone', value);
  };
  React.useEffect(() => {
    // Obtém o valor do celular armazenado na sessionStorage
    const value = sessionStorage.getItem(SessionStorageKeys.CELULAR_JA_SOU_CLIENTE);

    // Verifica se o valor existe
    if (value) {
      // Atualiza o estado do controle do formulário com o valor do celular
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: value,
            }
          }
        }
      });
    }
  }, [dispatchFormState]);

  React.useEffect(() => {
    // Verifica se o comprimento do valor do celular é igual ao comprimento mínimo esperado
    if (formState.controls.celular.value.length === MIN_CELLPHONE_LENGTH) {
      // Obtém o valor do celular do estado do formulário
      const value = formState.controls.celular.value;
      // Valida o número do celular utilizando o serviço UtilsService
      const result = Utils.validarCelular(value);

      // Atualiza o estado do controle do formulário com base no resultado da validação
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              valid: result.valid, // Define se o celular é válido ou não
              error: !result.valid ? result.errorMessage : undefined // Define a mensagem de erro, caso o celular seja inválido
            }
          }
        }
      });
    } else {
      // O valor do celular não possui o comprimento mínimo esperado, então não é possível validar
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              valid: undefined, // Define como undefined
              error: undefined // Limpa a mensagem de erro
            }
          }
        }
      });
    }
  }, [dispatchFormState, formState.controls.celular.value]);

  return (
    <div className="mdn-Col-xs-12">
      <Input
        id="celular"
        name="celular"
        phoneMobile
        ref={inputRef}
        required={formState.controls.celular.required}
        hasError={formState.controls.celular.valid === false}
        errorMessage={formState.controls.celular.error}
        disabled={formState.controls.celular.disabled}
        value={formState.controls.celular.value}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      >
        Celular
      </Input>
    </div>
  );
}

export default CelularControl;
