import axios from "axios";
import { useState, useEffect } from "react";

const useIP = () => {
  const [ip, setIp] = useState<string | null>(null);

  const getIPAddress = async () => {
    try {
      const { data } = await axios.get('https://api.ipify.org?format=json')
      setIp(data.ip)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIPAddress()
  }, []);

  return { ip };
}

export default useIP;