interface IFeatureFlag {
  allowAuthModalByNetwork: boolean
  allowOfferByNetwork: boolean
  isCepSemNumeroEnabled: boolean
  allowCtrlFormVersion: boolean
}

interface IFeatureFlagReturn {
  data: IFeatureFlag
}

interface IFeatureFlagService {
  get: () => Promise<IFeatureFlagReturn>
}

type TEnvParams = string

const featureFlagService: IFeatureFlagService = {
  get: async () => {
    try {
      const env = process.env.prospectUrl as unknown as TEnvParams
      const response = await fetch(`${env}/cobertura/api/feature-flag`)
      const json = await response.json()
      return {
        data: json
      }
    } catch (error) {
      console.log("Erro ao buscar feature flag: ", error)
    }
  }
}

export default featureFlagService
