import axios from "axios"
import { useAtom } from "jotai"
import { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { ModalWrapper } from "../components/Modal/ModalWrapper"
import BannerPromocionalMCM from "../components/Modal/Modals/BannerPromocionalMCM/BannerPromocionalMCM"
import JaSouClienteComponent from "../components/Modal/Modals/jornada-ja-sou-cliente/JaSouClienteComponent"
import ThemeContextProvider from "../contexts/theme"
import { useFeatureFlag, useGeneralRules, useSessionStorage } from "../hooks"
import useIP from "../hooks/useIP"
import CroService from "../services/CroService"
import ModalService from "../services/ModalService"
import { internalControlAtom } from "../store/internal-control"
import "../styles/global.scss"
import { ModalSettings } from "../types/ModalSettings"

const cname = "clr_hcms_asn"
const stnames = {
  aso: "autonomous_system_organization",
  mcc: "mobile_country_code",
  mnc: "mobile_network_code",
  acn: "already_client_network",
  caa: "client_already_authenticated",
  cmpa: "claro-movel-promo-applied",
  asmo: "allow_show_modal_offer",
  affiliateId: "affiliateId"
}

function App({ Component, pageProps }: AppProps) {
  const { ip } = useIP()
  const { storyblok, response } = useGeneralRules()
  const { setItem: setASOItem, getItem: getASOItem } = useSessionStorage(
    stnames.aso
  )
  const {
    setItem: setMCCItem,
    getItem: getMCCItem,
    delItem: delMCCItem
  } = useSessionStorage(stnames.mcc)
  const {
    setItem: setMNCItem,
    getItem: getMNCItem,
    delItem: delMNCItem
  } = useSessionStorage(stnames.mnc)
  const { setItem: setACNItem } = useSessionStorage(stnames.acn)
  const { getItem: getCMPAItem } = useSessionStorage(stnames.cmpa)
  const { getItem: getCAAItem } = useSessionStorage(stnames.caa)
  const { getItem: getAffiliateIdItem } = useSessionStorage(stnames.affiliateId)
  const { getItem: getASMOItem, setItem: setASMOItem } = useSessionStorage(
    stnames.asmo
  )
  const [alreadyClientNetwork, setAlreadyClientNetwork] = useState(false)
  const [clientMobilePromoApplied, setClientMobilePromoApplied] =
    useState(undefined)
  const globalModalRef = useRef(null)
  const { featureFlag } = useFeatureFlag()

  useEffect(() => {
    ModalService.registerModal(globalModalRef.current)
  }, [])

  useEffect(() => {
    const htSkipDiv: HTMLElement = document.querySelectorAll(
      "body > div.ht-skip > div"
    )[0] as HTMLElement
    if (htSkipDiv) {
      htSkipDiv.style.pointerEvents = "none"
    }
  }, [])

  const checkNetworksToOpenModal = (currNetwork: string) => {
    const networks = ["Claro SA", "CLARO S.A."].map((network) =>
      network.toLowerCase().trim()
    )
    return networks.includes(currNetwork.toLowerCase().trim())
  }

  const updateNetworkInfo = () => {
    const aso = getASOItem()
    const mcc = getMCCItem()
    const mnc = getMNCItem()
    const cmpa = getCMPAItem()
    const hasAtLeastOne = Boolean(mnc || mcc)
    const acn = Boolean(hasAtLeastOne && checkNetworksToOpenModal(aso))
    setACNItem(acn)
    setAlreadyClientNetwork(acn)

    if (cmpa) setClientMobilePromoApplied(cmpa === "false")
    if (!acn) {
      delMCCItem()
      delMNCItem()
    }
  }

  const getASOFromApi = async () => {
    try {
      const {
        data: { asn }
      } = await axios.get(`${process.env.cmsUrl}/api/segments/network?ip=${ip}`)
      const aso = asn.autonomous_system_organization
      const mcc = asn.mobile_country_code
      const mnc = asn.mobile_network_code
      setASOItem(aso)
      mcc && setMCCItem(mcc)
      mnc && setMNCItem(mnc)
      updateNetworkInfo()
      CroService.postDataLayer({
        event: "event",
        eventCategory: "plano-claro-res:cobertura",
        eventAction: aso,
        eventLabel: "rede-do-cliente"
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!ip) return
    getASOFromApi()
  }, [ip])

  useEffect(() => {
    if (featureFlag?.allowOfferByNetwork) return
    if (!featureFlag?.allowAuthModalByNetwork) return

    const conditional =
      alreadyClientNetwork &&
      storyblok &&
      (clientMobilePromoApplied === undefined || clientMobilePromoApplied)

    const clientAlreadyAuthenticated = getCAAItem() === "true"

    if (clientAlreadyAuthenticated) return
    if (!conditional) return

    ModalService.open({
      size: "lg",
      component: JaSouClienteComponent,
      componentProps: {
        props: {
          storyBlok: response
        }
      },
      showHeader: false,
      bannerMode: true,
      taguear: true,
      tags: {
        abertura: {
          event: "event",
          eventCategory:
            "planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios",
          eventAction: "modal:abriu",
          eventLabel: "cliente-claro-movel-beneficios"
        },
        fechamento: {
          event: "event",
          eventCategory:
            "planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios",
          eventAction: "modal:fechou",
          eventLabel: "cliente-claro-movel-beneficios"
        }
      }
    })
  }, [storyblok, alreadyClientNetwork, clientMobilePromoApplied])

  const [storyblokPromotionalBanners, setPromotionalBanners] = useState<any[]>(
    []
  )

  const getPromotionalBanners = async () => {
    try {
      type StoryBlokEnv = {
        api: string
        cv: string
        version: string
        token: string
      }
      const env = process.env.storyBlokApi as unknown as StoryBlokEnv
      const params = {
        "filter_query[offer_by_network][in]": true,
        starts_with:
          "residencial/prospect/cliente-claro-movel/promotional-banners-rede-movel/",
        token: env.token,
        version: env.version
      }
      const { data } = await axios.get(env.api, { params })
      if (!data.stories.length) return
      setPromotionalBanners(
        data.stories.map((story) => {
          const { content, slug } = story
          const storyInfo = {
            ...content,
            slug
          }
          return storyInfo
        })
      )
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (storyblokPromotionalBanners.length) return // se já houver banners promocionais, return
    if (!featureFlag?.allowOfferByNetwork) return // se a feature de oferta por rede não estiver habilitada, return

    getPromotionalBanners()
  }, [storyblokPromotionalBanners, featureFlag?.allowOfferByNetwork])

  const [internalControl, setInternalControl] = useAtom(internalControlAtom)
  const router = useRouter()

  useEffect(() => {
    if (!storyblok) return
    if (!router.isReady) return

    const products = [
      "tvId",
      "internetId",
      "celularId",
      "foneId",
      "additionalIds",
      "subprodutosIds"
    ]

    const hasProduct = products.some((product) => router.query[product])
    const hasProductInSession = products.some((product) =>
      sessionStorage.getItem(product)
    )

    if (hasProduct || hasProductInSession) {
      setASMOItem("false")
      return setInternalControl({ allowToOpenOfferByNetwork: false })
    } else {
      setASMOItem("true")
      return setInternalControl({ allowToOpenOfferByNetwork: true })
    }
  }, [storyblok])

  useEffect(() => {
    if (!storyblok && !featureFlag) return
    const currAffiliateId = getAffiliateIdItem()
    const affiliatesId = storyblok?.claroMobileCustomer.offersAvailable

    const affiliateIncludedInStoryBlok = affiliatesId?.includes(currAffiliateId)
    const allowOfferByNetworkForAll = storyblok?.claroMobileCustomer.allowOfferByNetworkForAll
    const hasPromotionalBanners = Boolean(storyblokPromotionalBanners.length)
    const allowByJourney = internalControl.allowToOpenOfferByNetwork

    if (!allowByJourney) return                     // se a feature de oferta por rede não estiver habilitada para a jornada, return
    if (!featureFlag?.allowOfferByNetwork) return   // se a feature de oferta por rede não estiver habilitada, return
    if (!allowOfferByNetworkForAll) {               // se a feature de oferta por rede não estiver habilitada para todos, return
      if (!affiliateIncludedInStoryBlok) return     // se o affiliateId não estiver incluso no storyblok, return
    }
    if (!hasPromotionalBanners) return              // se não houver banners promocionais, return
    if (!alreadyClientNetwork) return               // se o usuário não for da rede Claro, return

    const size = window.innerWidth >= 768 ? "lg" : "sm" 
    const promotionalBannersProps = { storyBlok: storyblokPromotionalBanners }

    ModalService.open({
      component: BannerPromocionalMCM,
      componentProps: promotionalBannersProps,
      bannerMode: true,
      bannerPromocional: true,
      size: size,
      disableOverflowY: true,
      disableOverflowX: true
    } as ModalSettings)
  }, [
    storyblok,
    response,
    alreadyClientNetwork,
    clientMobilePromoApplied,
    storyblokPromotionalBanners,
    featureFlag?.allowOfferByNetwork,
    internalControl
  ])

  return (
    <>
      <Head>
        <title>Monte seu Combo CLARO OFICIAL - No site é mais Barato</title>
        <meta
          name="Consulta de cobertura Claro"
          content="Consulta de cobertura de planos Claro"
        />
        <meta name="description" content="Consulta de cobertura Claro" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ThemeContextProvider>
        <Component {...pageProps} />
        <ModalWrapper ref={globalModalRef} />
      </ThemeContextProvider>
    </>
  )
}

export default App
