import React, { useEffect, useState } from 'react';

import ApiErrorComponent from './api-error/ApiErrorComponent';
import SendCodeComponent from './send-code/SendCodeComponent';
import ViabilityErrorComponent from './viability-error/ViabilityErrorComponent';
import NotMobileCustomerComponent from './not-mobile-customer/NotMobileCustomerComponent';
import MobileClientValidationComponent from './mobile-client-validation/MobileClientValidationComponent';

import JaSouClienteContext from '../../../../contexts/jornada-ja-sou-cliente';
import { Step } from '../../../../types/JornadaJaSouCliente/Step';
import { SessionStorageKeys } from '../../../../types/SessionStorageKeys';

const JaSouClienteComponent: React.FC = (props: any) => {
  const [step, setStep] = useState<Step>('sendCode');
  const [isResidentialCustomer, setIsResidentialCustomer] = React.useState<boolean | undefined>(undefined);
  const [isMobileCustomer, setIsMobileCustomer] = React.useState<boolean | undefined>(undefined);
  const [outroEndereco, setOutroEndereco] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    // Recuperar o valor da chave OUTRO_ENDERECO_JA_SOU_CLIENTE da sessionStorage
    const outroEnderecoValue = sessionStorage.getItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE);
    // Recuperar o valor da chave RESIDENTIAL_CUSTOMER_JA_SOU_CLIENTE da sessionStorage
    const isResidentialCustomerValue = sessionStorage.getItem(SessionStorageKeys.RESIDENTIAL_CUSTOMER_JA_SOU_CLIENTE);

    if (outroEnderecoValue === 'true') { setOutroEndereco(true) }
    else if (outroEnderecoValue === 'false') { setOutroEndereco(false) }

    if (isResidentialCustomerValue === 'true') { setIsResidentialCustomer(true) }
    else if (isResidentialCustomerValue === 'false') { setIsResidentialCustomer(false) }
  }, []);

  useEffect(() => {
    if (!props.overlayClicked) return
    sessionStorage.removeItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE);
  }, [props])
  
  return (
    <JaSouClienteContext.Provider value={{
      step: step,
      mobileCustomer: isMobileCustomer,
      residentialCustomer: isResidentialCustomer,
      outroEndereco: outroEndereco,
      storyBlok: props,
      navigateTo: (nextStep) => setStep(nextStep),
      setMobileCustomer: (newState: boolean) => setIsMobileCustomer(newState),
      setResidentialCustomer: (newState: boolean) => setIsResidentialCustomer(newState),
      setOutroEndereco: (newState: boolean) => setOutroEndereco(newState),
    }}>
      {step === 'sendCode' && <SendCodeComponent />}
      {step === 'mobileClientValidation' && <MobileClientValidationComponent />}
      {step === 'notMobileCustomer' && <NotMobileCustomerComponent />}
      {step === 'apiError' && <ApiErrorComponent />}
      {step === 'viabilityError' && <ViabilityErrorComponent />}
    </JaSouClienteContext.Provider>
  );
}

export default JaSouClienteComponent;
