import {
  birthdate,
  cep,
  cpf,
  email,
  name,
  number,
  phone,
  rg
} from "../utils/validators"

export type ValidationKeysT =
  | "birthdate"
  | "cep"
  | "cpf"
  | "email"
  | "name"
  | "motherName"
  | "number"
  | "phone"
  | "rg"

type IValidationService = {
  [key in ValidationKeysT]: (value: string) => {
    message: string
    regex: RegExp
    valid: boolean
  }
}

const validationService: IValidationService = {
  birthdate,
  cep,
  cpf,
  email,
  name,
  motherName: name,
  number,
  phone,
  rg
}

export default validationService
