

const regex = /^(\w+(?:\.\w+){0,}?)@(?:[a-z0-9-]+\.)+[a-z]{2,}$/

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: { message: "O campo deve conter um e-mail válido.", valid: false, regex },
}

export const email = (value) => {
  const email = String(value).replace(/\s/g, "")
  if (!email) return responses.empty

  const validation = regex.test(email)
  if (!validation) return responses.invalid
  return responses.valid
}
