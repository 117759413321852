import { useState } from "react";
import MobileInfoService from "../services/MobileInfoService";
import { MobileInfoResponse } from "../types/IdentificationApi/MobileResponse";
import { AxiosError } from "axios";

const useMobileInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [mobileInfo, setMobileInfo] = useState<MobileInfoResponse | null>(null);

  /**
   * Sends an SMS to the provided MSISDN (phone number).
   * @param msisdn - The MSISDN (phone number) to send the SMS to.
   */
  const sendSMS = async (msisdn: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await MobileInfoService.sendSMS(msisdn);
      const token = response.data.data.token;
      setToken(token);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Queries customer information based on the provided CPF, MSISDN, code, and token.
   * @param cpf - The CPF of the customer to be queried.
   * @param msisdn - The MSISDN (phone number) of the customer to be queried.
   * @param code - The code received via SMS.
   * @param token - The token used to validate the code. Obtained from the sms-ecare API.
   */
  const consultarMobileInfo = async (cpf: string, msisdn: string, code: string, token: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await MobileInfoService.consultar(cpf, msisdn, code, token);
      setMobileInfo(response.data);
      return {
        status: 'success',
      }
    } catch (error) {
      setError(error);
      return {
        status: 'invalid',
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    mobileInfo,
    token,
    sendSMS,
    consultarMobileInfo,
  };
};

export default useMobileInfo;
