export const makeQueryString = (productsId: { [x: string]: string | string[] }) => {
    const query = Object.keys(productsId)
    .map(k => {
      const key = k as keyof typeof productsId
      const value = Array.isArray(productsId[key]) ? (productsId[key] as []).join(',') : productsId[key]
      if (!value) return ''
      return `${key}=${value}`
    })
    .filter(Boolean)
    .join('&')
  return query
}

export const parseQueryString = ({ key }: any) => {
  const parsed = makeQueryString(
    key.tbody.reduce((acc: any, curr: any) => {
      const key = curr.body[0].value
      const value = curr.body[1].value

      const parsed = {
        ...acc,
        [key]: value
      }
      return parsed
    }, {})
  )

  return parsed
}
