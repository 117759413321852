export interface IPorteiraResponse {
  app_tv_ids: string
  box_tv_ids: string
  component: string
  soundbox_cabo_tv_ids: string
  soundbox_tv_ids: string
}

export const resolvePorteira = (story: any): IPorteiraResponse => {
  return {
    app_tv_ids: story.data.stories[0].content?.porteira[0]?.app_tv_ids,
    box_tv_ids: story.data.stories[0].content?.porteira[0]?.box_tv_ids,
    component: story.data.stories[0].content?.porteira[0]?.component,
    soundbox_cabo_tv_ids:
      story.data.stories[0].content?.porteira[0]?.soundbox_cabo_tv_ids,
    soundbox_tv_ids: story.data.stories[0].content?.porteira[0]?.soundbox_tv_ids
  }
}
