import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react"
import validationService, {
  ValidationKeysT
} from "../services/ValidationService"
import useDebounce from "./useDebounce"
import useTagging from "./useTagging"

interface IInputsConfig<T> {
  id: keyof T
  label: string
  defaultValue: T[keyof T]
  type: "text" | "number"
  noZeroLeft?: boolean
  validationType: ValidationKeysT
  focusAfterFill?: MutableRefObject<HTMLInputElement>
  maxLength?: number
}

interface IConfigForm<T> {
  tagging: {
    category: string
  }
  inputs: IInputsConfig<T>[],
}

type TCheckmarkValue = "none" | "success" | "error"

const useForm = <T,>({ inputs, tagging }: IConfigForm<T>) => {
  const { keys, labels } = useMemo(
    () => ({
      labels: inputs.reduce((acc, field) => {
        return {
          ...acc,
          [field.id]: field.label
        }
      }, {} as T),
      keys: inputs.map((input) => input.id)
    }),
    [inputs]
  )

  const [state, setState] = useState<T>(
    inputs.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: field.defaultValue
      } as T
    }, {} as T)
  )

  const [errors, setErrors] = useState<T>(
    inputs.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: ""
      } as T
    }, {} as T)
  )

  const [croAlreadySent, setCroAlreadySent] = useState<T>(
    inputs.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: false
      } as T
    }, {} as T)
  )


  const [checkmark, setCheckmark] = useState<Record<keyof T, TCheckmarkValue>>(
    inputs.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: 'none'
      }
    }, {} as Record<keyof T, TCheckmarkValue>)
  )

  const [formIsValid, setFormIsValid] = useState(false)

  const [debouncedStates] = useDebounce(state, 2)

  const [focusedValues] = useDebounce(state)

  const { handleTagging } = useTagging(tagging.category)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      const specialInputs = ["email"]
      const withoutSpecialChars = ["name", "motherName"]
      const numberValues = ["cpf", "phone", "rg", "cep", "number"]

      if (
        inputs.find((input) => input.id === name)?.noZeroLeft &&
        value.startsWith("0")
      )
        return

      handleErrors(name, value)

      if (inputs.find((input) => input.id === name)?.maxLength) {
        const maxLength = inputs.find((input) => input.id === name)?.maxLength
        if (value.length > maxLength) return
      }

      if (numberValues.includes(name))
        return setState({
          ...state,
          [name]: value.replace(/[^0-9]/g, "")
        })

      if (specialInputs.includes(name))
        return setState({
          ...state,
          [name]: value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^a-zA-Z0-9@._+-]/g, "")
        })

      if (withoutSpecialChars.includes(name))
        return setState({
          ...state,
          [name]: value.replace(/[^a-zA-ZÀ-ú\s~´]/g, "")
        })

      return setState({ ...state, [name]: value })
    },
    [inputs, state, setState]
  )

  const handleErrors = useCallback(
    (name: string, value: string) => {
      const message =
        validationService[name as keyof typeof validationService](value).message
      setErrors({ ...errors, [name]: message })
      setCheckmark({
        ...checkmark,
        [name]: message ? "error" : checkmark[name] ? "success" : "none" as TCheckmarkValue
      })
    },
    [errors, setErrors, validationService, checkmark, setCheckmark]
  )

  const handleFocus = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target

      handleTagging({
        eventAction: "interacao:campo",
        eventLabel: `entrou:${labels[name]}`
      })
    },
    [tagging.category, labels]
  )

  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target

      const message =
        validationService[name as keyof typeof validationService](value).message
      setErrors({ ...errors, [name]: message })
      setCheckmark({
        ...checkmark,
        [name]: message ? "error" : state[name] ? "success" : "none" as TCheckmarkValue
      })

      handleTagging({
        eventAction: `interacao:campo`,
        eventLabel: `saiu:${labels[name]}`
      })
    },
    [errors, setErrors, tagging.category, labels]
  )

  useEffect(() => {
    // input tag rule
    keys.forEach((input) => {
      if (state[input] === "") {
        // se for vazio não envia o evento
        setCroAlreadySent((prev) => ({ ...prev, [input]: false }))
        return
      }
      if (state[input] !== debouncedStates[input]) {
        // se o estado atual for diferente ao debounced não envia o evento
        setCroAlreadySent((prev) => ({ ...prev, [input]: false }))
        return
      }
      if (croAlreadySent[input]) return // se já enviou o evento não envia novamente

      handleTagging({
        eventAction: "interacao:campo",
        eventLabel: `preencheu:${labels[input]}`
      })

      setCroAlreadySent((prev) => ({ ...prev, [input]: true }))
    })
  }, [tagging.category, debouncedStates, errors])

  useEffect(() => {
    // form validation
    const formValidation = inputs.map(({ id, validationType }) => {
      return validationService[validationType]?.(state[id] as string)
    })
    const valid = formValidation.every((input) => input.valid)
    setFormIsValid(valid)
  }, [errors, state, validationService, formIsValid])

  useEffect(() => {
    const target = inputs
      .map((input) => {
        return {
          from: input.id as ValidationKeysT,
          to: input?.focusAfterFill?.current
        }
      })
      .filter((input) => input.from && input.to)
    target.forEach(({ from, to }) => {
      const valid = validationService[from]?.(state[from])?.valid

      if (!valid) return
      if (state[from] === focusedValues[from]) return

      to.focus()
    })
  }, [validationService, state, focusedValues])

  return {
    state,
    errors,
    checkmark,
    formIsValid,
    debouncedStates,
    setState,
    setErrors,
    setCheckmark,
    handleBlur,
    handleFocus,
    handleErrors,
    handleChange,
  }
}

export default useForm
