interface Image {
  id: number
  alt: string
  src: string
}
interface IClaroMobileCustomerResponse {
  component: string
  isEnable: boolean
  listPromotionalplans: string[]
  noFidelity: string
  offersAvailable: string[]
  promoId: string
  promoType: string
  imageDesktop: Image
  imageMobile: Image
  allowOfferByNetworkForAll: boolean
}

export const resolveClaroMobileCustomer = (
  story: any
): IClaroMobileCustomerResponse => {
  return {
    isEnable: story.data.stories[0].content.cliente_claro_movel[0].isEnable,
    imageDesktop:
      story.data.stories[0].content.cliente_claro_movel[0]["Imagem_Desktop"],
    imageMobile:
      story.data.stories[0].content.cliente_claro_movel[0]["Imagem_Desktop"],
    listPromotionalplans:
      story.data.stories[0].content.cliente_claro_movel[0]?.listPromotionalplans,
    noFidelity: story.data.stories[0].content.cliente_claro_movel[0]?.noFidelity,
    offersAvailable:
      story.data.stories[0].content.cliente_claro_movel[0]?.offersAvailable,
    promoId: story.data.stories[0].content.cliente_claro_movel[0]?.promoId,
    promoType: story.data.stories[0].content.cliente_claro_movel[0]?.promoType,
    component: story.data.stories[0].content.cliente_claro_movel[0]?.component,
    allowOfferByNetworkForAll:
      story.data.stories[0].content.cliente_claro_movel[0]
        ?.allowOfferByNetworkForAll,
  }
}
