const regex = /\D/g

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: { message: "O campo deve conter um CPF válido.", valid: false, regex }
}

export const cpf = (value) => {
  const cpf = value.replace(regex, "")
  if (!cpf) return responses.empty // se o campo estiver vazio
  const digits = cpf.split('').map(x => parseInt(x))
  const sameDigit = digits.every(x => x === digits[0])

  if (sameDigit) return responses.invalid // se todos os dígitos forem iguais

  const sumFirstPart = digits.slice(0, 9).reduce((acc, val, idx) => acc + val * (10 - idx), 0)
  const firstCheckDigit = (sumFirstPart * 10) % 11 === 10 ? 0 : (sumFirstPart * 10) % 11
  if (firstCheckDigit !== digits[9]) return responses.invalid // se o primeiro dígito verificador não for igual ao décimo dígito

  const sumSecondPart = digits.slice(0, 10).reduce((acc, val, idx) => acc + val * (11 - idx), 0)
  const secondCheckDigit = (sumSecondPart * 10) % 11 === 10 ? 0 : (sumSecondPart * 10) % 11
  if (secondCheckDigit !== digits[10]) return responses.invalid // se o segundo dígito verificador não for igual ao décimo primeiro dígito

  return responses.valid
}