import React, { ChangeEvent } from 'react';
import { Checkbox } from 'mondrian-react';

import FormContext from '../context/FormContext';
import { FormActionTypes } from '../types/FormReducer';
import { SessionStorageKeys } from '../../../../../../../types/SessionStorageKeys';

import CroService from '../../../../../../../services/CroService';

const CheckboxControl: React.FC = () => {
  const { formState, dispatchFormState } = React.useContext(FormContext);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked;

    // Salva o valor no sessionStorage
    saveToSessionStorage(checked);

    // Atualiza o estado do controle de checkbox com o novo valor
    dispatchFormState({
      type: FormActionTypes.UPDATE_CHECKBOX_CONTROL,
      payload: {
        control: {
          name: 'checkbox',
          state: {
            checked,
            touched: true,
          },
        }
      }
    });

    // Envia um evento para o serviço CroService indicando a interação com o checkbox
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: checked ? 'interacao:checkbox' : 'interacao:checkbox',
      eventLabel: checked ? 'marcou:sem-numero' : 'desmarcou:sem-numero'
    });
  };

  const saveToSessionStorage = (value: boolean) => {
    const key = SessionStorageKeys.CEP_SEM_NUMERO_JA_SOU_CLIENTE;

    // Salva o valor no sessionStorage
    window.sessionStorage.setItem(key, value.toString());
  };

  React.useEffect(() => {
    const checked = sessionStorage.getItem(SessionStorageKeys.CEP_SEM_NUMERO_JA_SOU_CLIENTE);

    if (checked === 'true') {
      // Atualiza o estado do controle de checkbox com base no valor armazenado no sessionStorage
      dispatchFormState({
        type: FormActionTypes.UPDATE_CHECKBOX_CONTROL,
        payload: {
          control: {
            name: 'checkbox',
            state: {
              checked: true,
              touched: true
            },
          }
        }
      });
    }
  }, [dispatchFormState]);

  return (
    <div className="mdn-Col-xs-12">
      <Checkbox
        id="cep-sem-numero"
        name="cep-sem-numero"
        checked={formState.controls.checkbox.checked}
        disabled={formState.controls.checkbox.disabled}
        onChange={handleOnChange}>
        Meu CEP não possui número
      </Checkbox>
    </div>
  );
}

export default CheckboxControl;
