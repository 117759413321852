
interface ValidationResult {
  valid: boolean;
  errorMessage?: string;
}

class UtilsService {

  constructor() { return }

  /**
   * Removes all non-numeric characters from a CPF number.
   * @param cpf - The CPF number to be cleaned.
   * @returns The cleaned CPF number.
   */
  limparCpf(cpf: string): string {
    const cpfNumerico = cpf.replace(/\D/g, '');
    return cpfNumerico;
  }

  /**
   * Validates a CPF number.
   * @param cpf - The CPF number to be validated.
   * @returns True if the CPF number is valid, false otherwise.
   */
  validarCpf(cpf: string): ValidationResult {
    const cpfNumerico = cpf.replace(/\D/g, '');

    if (cpfNumerico.length !== 11) {
      return { valid: false, errorMessage: 'O CPF deve conter 11 dígitos' };
    }

    const todosDigitosIguais = cpfNumerico.split('').every(digito => digito === cpfNumerico[0]);
    if (todosDigitosIguais) {
      return { valid: false, errorMessage: 'O CPF não pode conter todos os dígitos iguais' };
    }

    const digitoVerificador1 = this.calcularDigitoVerificador(cpfNumerico.slice(0, 9));
    const digitoVerificador2 = this.calcularSegundoDigitoVerificador(cpfNumerico.slice(0, 9) + digitoVerificador1.toString());

    if (cpfNumerico.slice(-2) !== digitoVerificador1.toString() + digitoVerificador2.toString()) {
      return { valid: false, errorMessage: 'O CPF é inválido' };
    }

    return { valid: true };
  }

  /**
   * Calculates the first verification digit of a CPF number.
   * @param cpfNumerico - The CPF number without the verification digits.
   * @returns The first verification digit.
   */
  calcularDigitoVerificador(cpfNumerico: string): number {
    const multiplicadores = [10, 9, 8, 7, 6, 5, 4, 3, 2];

    const soma = cpfNumerico
      .split('')
      .map((digito, i) => parseInt(digito) * multiplicadores[i])
      .reduce((total, valor) => total + valor);

    const resto = soma % 11;

    const digito = resto < 2 ? 0 : 11 - resto;

    return digito;
  }

  /**
   * Calculates the second verification digit of a CPF number.
   * @param cpfNumerico - The CPF number with the first verification digit.
   * @returns The second verification digit.
   */
  calcularSegundoDigitoVerificador(cpfNumerico: string): number {
    const multiplicadores = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

    const soma = cpfNumerico
      .split('')
      .map((digito, i) => parseInt(digito) * multiplicadores[i])
      .reduce((total, valor) => total + valor);

    const resto = soma % 11;

    const digito = resto < 2 ? 0 : 11 - resto;

    return digito;
  }

  limparCelular(value: string) {
    const celularNumerico = value.replace(/\D/g, '');
    return celularNumerico;
  }

  public validarCelular(numero: string): { valid: boolean, errorMessage: string } {
    // Verifies if the number is a string with 11 characters
    if (typeof numero !== 'string' || numero.length !== 11) {
      return {
        valid: false,
        errorMessage: 'O número deve conter 11 dígitos'
      };
    }

    // Verifies if the number starts with the digit 9
    if (numero.charAt(2) !== '9') {
      return {
        valid: false,
        errorMessage: 'O número deve começar com o dígito 9'
      };
    }

    // Verifies if all digits are the same
    const firstDigit = numero.charAt(0);
    if (numero.split('').every(digito => digito === firstDigit)) {
      return {
        valid: false,
        errorMessage: 'O número não pode ter todos os dígitos iguais'
      };
    }

    // Verifies if all remaining characters are numeric digits
    for (let i = 0; i < numero.length; i++) {
      if (i === 2) {
        continue; // Skips the index of the digit 9
      }
      const digito = parseInt(numero.charAt(i), 10);
      if (isNaN(digito)) {
        return {
          valid: false,
          errorMessage: 'O número deve conter somente dígitos numéricos'
        };
      }
    }

    // If it passed all validations, the number is considered valid
    return {
      valid: true,
      errorMessage: undefined
    };
  }

  limparCep(value: string) {
    const cepNumerico = value.replace(/\D/g, '');
    return cepNumerico;
  }

  validarCep(cep: string): { valid: boolean, errorMessage?: string } {
    // Remove any non-numeric characters from the input
    cep = cep.replace(/\D/g, '');

    // Verifies if the input has a valid length
    if (cep.length !== 8) {
      return {
        valid: false,
        errorMessage: 'O CEP deve conter 8 dígitos'
      };
    }

    // Verifies if all characters are numeric digits
    for (let i = 0; i < cep.length; i++) {
      const digito = parseInt(cep.charAt(i), 10);
      if (isNaN(digito)) {
        return {
          valid: false,
          errorMessage: 'O CEP deve conter somente dígitos numéricos'
        };
      }
    }

    // Verifies if the CEP has no sequence of repeated digits
    if (/(\d)\1{7}/.test(cep)) {
      return {
        valid: false,
        errorMessage: 'O CEP não pode conter sequência de números iguais'
      };
    }

    // If it passed all validations, the CEP is considered valid
    return {
      valid: true
    };
  }

  validarNumeroResidencia(numero: string): { valid: boolean, errorMessage?: string } {
    // Verifica se o número é uma string não vazia
    if (typeof numero !== 'string' || numero.trim().length === 0) {
      return {
        valid: false,
        errorMessage: 'O número é obrigatório'
      };
    }

    // Verifica se o número é um valor numérico válido
    const numeroNumerico = parseInt(numero, 10);
    if (isNaN(numeroNumerico)) {
      return {
        valid: false,
        errorMessage: 'O número da residência deve ser um valor numérico'
      };
    }

    // Verifica se o número é maior que zero
    if (numeroNumerico <= 0) {
      return {
        valid: false,
        errorMessage: 'O número da residência deve ser maior que zero'
      };
    }

    // Se passou em todas as validações, o número é válido
    return {
      valid: true
    };
  }

  /**
   * Receives a string representing a phone number and returns a masked version of it.
   * @param numero - The phone number to be masked.
   * @returns A string representing the masked phone number.
   * The function formats the area code and the first and last digits of
   * the phone number, masking the rest with asterisks.
   *
   * If the input string does not have 11 digits, the function
   * returns the original input string.
   */
  mascararNumeroCelular(numero: string): string {
    // Remove todos os caracteres não numéricos
    const digits = numero.replace(/\D/g, '');

    // Verifica se o número possui 11 dígitos (incluindo o DDD)
    if (digits.length === 11) {
      const ddd = digits.substr(0, 2);
      const dddFormatado = `(${ddd})`;
      const parte1 = digits.substr(2, 1);
      const parte3 = digits.substr(7, 4);

      return `${dddFormatado} ${parte1} **** ${parte3}`;
    }

    // Retorna o número original se não for possível formatá-lo
    return numero;
  }

}

export default new UtilsService()
