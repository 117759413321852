import { atom } from "jotai"

export interface IFeatureFlagState {
  data: {
    allowAuthModalByNetwork: boolean
    allowOfferByNetwork: boolean
    isCepSemNumeroEnabled: boolean
    allowCtrlFormVersion: boolean
  }
}

const defaultState = atom<IFeatureFlagState>({} as IFeatureFlagState)

export const featureFlagAtom = atom(
  (get) => get(defaultState),
  (get, set, update: Partial<IFeatureFlagState>) => {
    set(defaultState, { ...get(defaultState), ...update })
  }
)
