import React, { useEffect } from 'react';
import ModalService from '../../../../services/ModalService';

import Image from 'next/image';
import { useTagging } from '../../../../hooks';
import { Slide } from '../../../../shared';
import { parseQueryString } from '../../../../utils';
import FormDrawer from '../../../EcomFormDrawer';
import styles from './BannerPromocionalMCM.module.scss';
interface ComponentProps {
  storyBlok: any[]
  overlayClicked: boolean
}


const BannerPromocionalMCM: React.FC<ComponentProps> = ({ storyBlok,overlayClicked }) => {
  const { handleTagging: handleModalTagging } = useTagging('planos-claro-res:cobertura:modal-acesso-rede-movel-claro')
  const { handleTagging: handleDrawerTagging } = useTagging('plano-claro-res:cobertura:modal-acesso-rede-movel-claro:drawer')
  const [imgBannersPromo, setImgBannersPromoState] = React.useState([])
  const [openDrawer, setOpenDrawer] = React.useState(undefined)
  const [mountedQueryString, setMountedQueryString] = React.useState('')
  const [imageDimensions, setImageDimensions] = React.useState({ width: 343, height: 549 })


  const handleOnClickBanner = (index: number): void => {
    const promoId = parseQueryString({ key: storyBlok[index].idProducts })
    const offerName = storyBlok[index].slug;
    const eventLabel = `contratar:${offerName}`
    setMountedQueryString(promoId)

    handleModalTagging({
      eventAction: 'clique:botao',
      eventLabel
    });

    sessionStorage.setItem('originId', 'cliente_rede-movel-cobertura')
    setOpenDrawer(true)
  }

  const handleButtonFecharClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    const eventLabel = 'fechar'
    handleModalTagging({
      eventAction: 'clique:botao',
      eventLabel
    });
    ModalService.close()
  }

  const handleOverlayClick = () => {
    if (!overlayClicked) return
    const eventLabel = 'fechar'
    handleModalTagging({
      eventAction: 'clique:fora',
      eventLabel
    });
    ModalService.close()
  }

  const handleClose = () => {
    setOpenDrawer(false)
    handleDrawerTagging({
      eventAction: "clique:botao",
      eventLabel: "fechar"
    })
  }

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setImageDimensions({ width: 787, height: 667 })
      setImgBannersPromoState(storyBlok.map(story => story.banner_desktop.filename))
    } else {
      setImageDimensions({ width: 343, height: 549 })
      setImgBannersPromoState(storyBlok.map(story => story.banner_mobile.filename))
    }
  }, [storyBlok, window.innerWidth])

  useEffect(() => {
    const eventLabel = 'modal-acesso-rede-movel-claro'
    handleModalTagging({
      eventAction: 'modal:abriu',
      eventLabel
    })
  }, [])


  useEffect(() => {
    handleOverlayClick()
  }, [overlayClicked])

  return (
    <>
      <div id="super-oferta" className={styles.superOferta}>
        <Slide banner={true} loop={true} className={styles.swiperBanner} slidesPerView={1}>
          {imgBannersPromo.map((imgBanner, i) => (
            <div className={`mdn-Banner-item ${styles.bannerItem}`} key={i} onClick={() => handleOnClickBanner(i)}>
              <Image
                src={imgBanner}
                alt={`Banner Promocional ${i + 1}`}
                width={imageDimensions.width}
                height={imageDimensions.height}
                priority
              />
            </div>
          ))}
        </Slide>
        <button id="button-fechar-modal"
          className={styles.buttonFecharModal}
          onClick={handleButtonFecharClick}>
        </button>
      </div>
      <FormDrawer
        open={openDrawer}
        mountedQueryString={mountedQueryString}
        handleClose={handleClose}
      />
    </>
  );
}

export default BannerPromocionalMCM;


