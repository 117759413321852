

const regex = /^([0-9]+([.-][0-9xX]+)*){3,}$/

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: { message: "O campo deve conter um RG válido.", valid: false, regex }
}

export const rg = (value) => {
  if (!value) return responses.empty
  if (value.length < 3) return responses.invalid
  const validation = regex.test(value)
  if (!validation) return responses.invalid
  return responses.valid
}
