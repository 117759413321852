import { makeQueryString } from "../utils"

type TStoryBlokEnvProps = {
  api: string
  token: string
  version: string
  cv: string
}

interface IGeneralRules {}

interface IGeneralRulesReturn {
  data: IGeneralRules
}

interface IGeneralRulesService {
  get: () => Promise<IGeneralRulesReturn>
}

const generalRulesService: IGeneralRulesService = {
  get: async () => {
    try {
      const env = process.env.storyBlokApi as unknown as TStoryBlokEnvProps
      const objParams = {
        starts_with: "residencial/prospect/regras-gerais",
        token: env.token,
        version: env.version
      }
      const queryParams = makeQueryString(objParams)
      const url = `${env.api}?${queryParams}`
      const response = await fetch(url)
      const json = await response.json()
      return {
        data: json
      }
    } catch (error) {
      console.log("Erro ao buscar regras gerais: ", error)
    }
  }
}

export default generalRulesService
