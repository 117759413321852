// mockedData.ts
import { ResidentialResponse } from '../types/IdentificationApi/ResidentialResponse';

export const mockedData: Record<string, ResidentialResponse> = {
  '94278965079': {
    data: {
      status: 'CONECTADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '71724684060': {
    data: {
      status: 'CONECTADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '71870208056': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '51944045066': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '78701196014': {
    data: {
      status: 'CONECTADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '30389125067': {
    data: {
      status: 'CONECTADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '87448761064': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '45510237015': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '72795594099': {
    data: {
      status: 'CONECTADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '60135869005': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '32477771051': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  '73292476003': {
    data: {
      status: 'NÃO ENCONTRADO',
    },
    meta: {
      code: '200',
      message: 'OK',
    },
    statusCode: 200,
  },
  // Adicione mais CPFs e dados mockados conforme necessário
};
