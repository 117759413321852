import { useEffect, useState } from "react"

const useDebounce = <T>(
  value: T,
  delay?: number
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, (delay || 0) * 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return [debouncedValue, setDebouncedValue]
}

export default useDebounce
