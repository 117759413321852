import { atom } from "jotai"

interface Image {
  id: number
  alt: string
  src: string
}
export interface IStoryblokState {
  data: {
    generalRules: {
      exclusiveCheckout: {
        affiliateIds: string[]
      }
      porteira: {
        app_tv_ids: string
        box_tv_ids: string
        component: string
        soundbox_cabo_tv_ids: string
        soundbox_tv_ids: string
      }
      blackFriday: {
        activated: boolean
        component: string
      }
      affiliateIds: string[]

      claroMobileCustomer: {
        component: string
        isEnable: boolean
        listPromotionalplans: string[]
        noFidelity: string
        offersAvailable: string[]
        promoId: string
        promoType: string
        allowOfferByNetworkForAll: boolean
        imageDesktop: Image
        imageMobile: Image
      }
    }
  }
  response: {
    generalRules: any
  }
}

const defaultState = atom<IStoryblokState>({} as IStoryblokState)

export const storyblokAtom = atom(
  (get) => get(defaultState),
  (get, set, update: Partial<IStoryblokState>) => {
    set(defaultState, { ...get(defaultState), ...update })
  }
)
