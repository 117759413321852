

const regex = /^[0-9]+$/

const responses = {
  valid: { message: "", valid: true, regex },
  empty: {
    message: "Preencha um número ou marque a opção abaixo.",
    valid: false,
    regex
  },
  invalid: {
    message: "O campo deve conter apenas números.",
    valid: false,
    regex
  }
}

export const number = (value) => {
  if (!value) return responses.empty

  const validation = regex.test(value)
  if (!validation) return responses.invalid
  return responses.valid
}
