import React, { ChangeEvent, FocusEvent } from 'react';
import { Input } from 'mondrian-react';

import FormContext from '../context/FormContext';
import { FormActionTypes } from '../types/FormReducer';
import { SessionStorageKeys } from '../../../../../../../types/SessionStorageKeys';

import Utils from '../../../../../../../services/UtilsService';
import CroService from '../../../../../../../services/CroService';

const CepControl: React.FC = () => {
  const { formState, dispatchFormState } = React.useContext(FormContext);

  const CONTROL_NAME = 'cep';
  const CEP_MIN_LENGTH = 8;

  const handleOnFocus = (): void => {
    // Atualiza o estado do controle do formulário quando o campo de CEP recebe o foco
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            touched: true, // Indica que o campo foi tocado
            focused: true, // Indica que o campo está com foco
          }
        }
      }
    });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = Utils.limparCep(e.target.value);
    saveToSessionStorage(value);
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            value, // Define o novo valor do CEP
          }
        }
      }
    });
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>): void => {
    const value = Utils.limparCep(e.target.value);
    const result = Utils.validarCep(value);
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            valid: result.valid, // Indica se o CEP é válido
            error: !result.valid ? result.errorMessage : undefined, // Define a mensagem de erro caso o CEP seja inválido
            focused: false, // Indica que o campo perdeu o foco
          }
        }
      }
    });

    if (value.length > 0) {
      // Registra um evento no serviço CroService caso o valor do CEP seja preenchido
      CroService.postDataLayer({
        event: 'event',
        eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
        eventAction: 'interacao:campo',
        eventLabel: 'preencheu:cep'
      });
    }
  };

  // Salva o valor do CEP na sessionStorage
  const saveToSessionStorage = (value: string) => {
    const key = SessionStorageKeys.CEP_JA_SOU_CLIENTE;
    window.sessionStorage.setItem(key, value);
  };

  // Verifica o valor do CEP no estado do formulário e atualiza o estado do controle correspondente
  React.useEffect(() => {
    if (formState.controls.cep.value.length === CEP_MIN_LENGTH) {
      const value = formState.controls.cep.value;
      const result = Utils.validarCep(value);

      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              valid: result.valid, // Indica se o CEP é válido
              error: !result.valid ? result.errorMessage : undefined, // Define a mensagem de erro caso o CEP seja inválido
              focused: false, // Indica que o campo não está com foco
            }
          }
        }
      });
    } else {
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              valid: undefined, // Define como undefined para indicar que o CEP não está pronto para validação
              error: undefined // Limpa a mensagem de erro
            }
          }
        }
      });
    }
  }, [dispatchFormState, formState.controls.cep.value]);

  React.useEffect(() => {
    // Verifica se há um valor de CEP salvo na sessionStorage e atualiza o estado do controle correspondente
    const value = sessionStorage.getItem(SessionStorageKeys.CEP_JA_SOU_CLIENTE);

    if (value) {
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: value, // Define o valor do CEP
            }
          }
        }
      });
    }
  }, [dispatchFormState]);

  return (
    <div className="mdn-Col-xs-12 mdn-Col-sm-7">
      <Input
        id="cep"
        name="cep"
        cep
        required={formState.controls.cep.required}
        hasError={formState.controls.cep.valid === false}
        errorMessage={formState.controls.cep.error}
        disabled={formState.controls.cep.disabled}
        value={formState.controls.cep.value}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
      >
        CEP
      </Input>
    </div>
  );
}

export default CepControl;
