import { DataLayerEvent } from "../types/DataLayerEvent"

class CroService {
  constructor() {
    return
  }

  /**
   * Sends a data layer event to the window object.
   * @param data - The data layer event to be sent.
   */
  postDataLayer(data: DataLayerEvent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = window
    obj.dataLayer = obj.dataLayer || []
    obj.dataLayer.push(data)
  }
}

export default new CroService()
