import React, { SyntheticEvent } from 'react';
import ModalService from '../../../../../services/ModalService';

import styles from './ViabilityErrorComponent.styles.module.scss';
import { Button, Heading, Text } from 'mondrian-react';
import { SessionStorageKeys } from '../../../../../types/SessionStorageKeys';

const ViabilityErrorComponent: React.FC = () => {
  const MONTE_SUA_COMBINACAO_URL = `${process.env.prospectUrl}/monte-sua-combinacao`;

  const navigateToVitrine = (e?: SyntheticEvent<Element, Event>): void => {
    e.preventDefault();
    const cep = sessionStorage.getItem(SessionStorageKeys.CEP_JA_SOU_CLIENTE);
    const numero = sessionStorage.getItem(SessionStorageKeys.NUMERO_JA_SOU_CLIENTE);
    const cepSemNumero = sessionStorage.getItem(SessionStorageKeys.CEP_SEM_NUMERO_JA_SOU_CLIENTE);

    if (cep && numero) {
      const url = `${MONTE_SUA_COMBINACAO_URL}?cep=${cep}&number=${numero}`;
      window.location.href = url;
    } else if (cepSemNumero === 'true') {
      const url = `${MONTE_SUA_COMBINACAO_URL}?cep=${cep}&number=SN`;
      window.location.href = url;
    } else {
      window.location.href = MONTE_SUA_COMBINACAO_URL;
    }
  }

  return (
    <div id="viability-error-container" className={styles.container}>
      <button id="button-fechar-modal"
        className={styles.buttonFecharModal}
        onClick={() => ModalService.close()}>
        <span className="mdn-Icon-circulo-fechar mdn-Icon--md"></span>
      </button>

      <div id="component-content" className={styles.content}>
        <div className={`mdn-Row ${styles.mdnRow}`}>
          <div className={`mdn-Col-xs-12 mdn-Col-sm-6 ${styles.illustrationPart}`}
            id='illustration-part' />
          <div className={`mdn-Col-xs-12 mdn-Col-sm-6 ${styles.messagePart}`}
            id='message-part'>
            <Heading sm>Ops!</Heading>

            <Text body>
              Infelizmente a tecnologia utilizada neste plano ainda não está disponível
              para a sua região, mas não se preocupe, você pode visitar nossa vitrine e
              selecionar seu novo plano.
            </Text>
            <Button type='button' className={styles.voltarBtn}
              primary
              onClick={navigateToVitrine}>
              Ir para vitrine de produtos
            </Button>
          </div>
        </div>
      </div >
    </div >
  );
}

export default ViabilityErrorComponent;
