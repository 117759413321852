import React, { Component } from "react"
import { ModalComponent } from "./ModalComponent";
import { ModalSettings } from "../../types/ModalSettings"

export class ModalWrapper extends Component {
  /**
   * Initializes the state of the component with an undefined
   * modal property.
   */
  state = {
    modal: undefined
  }

  /**
   * Defines an open method that takes in a ModalSettings object
   * as a parameter and sets the state of the component with the
   * passed in object.
   * @param params - A ModalSettings object that contains the
   * settings for the modal.
   */
  open = (params: ModalSettings) => {
    this.setState({ modal: params })
  }

  /**
   * Defines a close method that sets the state of the component
   * with an undefined modal property.
   */
  close = () => {
    this.setState({ modal: undefined })
  }

  /**
   * Renders the component and returns a ModalComponent if the modal
   * property is defined in the state.
   * @returns A ModalComponent if the modal property is defined in the
   * state, otherwise returns an empty fragment.
   */
  render() {
    const { modal } = this.state
    return <>{modal && <ModalComponent {...modal} />}</>
  }
}
