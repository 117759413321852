import React, { SyntheticEvent } from 'react';
import { Button, Heading, Text } from 'mondrian-react';
import ModalService from '../../../../../services/ModalService';
import CroService from '../../../../../services/CroService';
import JaSouClienteContext from '../../../../../contexts/jornada-ja-sou-cliente';
import { SessionStorageKeys } from '../../../../../types/SessionStorageKeys';

import styles from './NotMobileCustomerComponent.module.scss';

const NotMobileCustomerComponent: React.FC = () => {
  const context = React.useContext(JaSouClienteContext);

  const navigateToVitrine = (e?: SyntheticEvent<Element, Event>): void => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'clique-aqui:tela-ops'
    });
    const cep = sessionStorage.getItem(SessionStorageKeys.CEP_JA_SOU_CLIENTE);
    const numero = sessionStorage.getItem(SessionStorageKeys.NUMERO_JA_SOU_CLIENTE);
    if (!!cep && !!numero) {
      window.location.href = `${process.env.prospectUrl}/monte-sua-combinacao?cep=${cep}&number=${numero}`;
    } else {
      ModalService.close();
    }
  }

  const handleFechar = (): void => {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'fechar:tela-ops'
    });
    ModalService.close();
  }

  const handleTentarNovamente = (e?: SyntheticEvent<Element, Event>): void => {
    e.preventDefault();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
      eventAction: 'clique:botao',
      eventLabel: 'tentar-novamente:tela-ops'
    });
    context.navigateTo('sendCode')
  }

  return (
    <div id="not-mobile-customer-container" className={styles.container}>
      <button id="button-fechar-modal"
        className={styles.buttonFecharModal}
        onClick={handleFechar}>
        <span className="mdn-Icon-circulo-fechar mdn-Icon--md"></span>
      </button>

      <Heading className={styles.modalHeading} md>Ops!</Heading>

      <div id="error-illustration" className={styles.errorIllustration} />

      <section id="tentar-novamente">
        <Heading xxs className={styles.tentarNovamenteHeading}>
          Necessitamos que os dados informados sejam do titular do contrato.
        </Heading>

        <Text body>
          Por favor insira o número e CPF do titular de uma linha Claro móvel.
        </Text>

        <Button id="tentar-novamente" primary className={styles.button}
          onClick={handleTentarNovamente}>
          Tentar novamente
        </Button>
      </section>

      <section id="conferir-outras-ofertas">
        <Heading xxs className={styles.conferirOutrasOfertas}>
          Se você não for um cliente Claro móvel, confira outras ofertas especiais para você!
        </Heading>

        <Button secondary className={styles.button} onClick={navigateToVitrine}>
          Clique aqui
        </Button>
      </section>
    </div>
  );
}

export default NotMobileCustomerComponent;
