import { MobileInfoErrorResponse, MobileInfoResponse } from '../types/IdentificationApi/MobileResponse';

interface MockedErrorResponse {
  type: 'error',
  response: MobileInfoErrorResponse
}

interface MockedSuccessResponse {
  type: 'success',
  response: MobileInfoResponse
}

export const mockedData: Record<string, MockedSuccessResponse | MockedErrorResponse> = {
  // Token expirado
  '94278965079': {
    type: 'error',
    response: {
      statusCode: 400,
      error: {
        code: '400',
        message: 'Bad Request',
      },
    }
  },
  // Token inválido
  '71724684060': {
    type: 'error',
    response: {
      statusCode: 404,
      error: {
        code: '404',
        message: 'Not Found',
      }
    }
  },
  // Código inválido
  '71870208056': {
    type: 'error',
    response: {
      statusCode: 406,
      error: {
        code: '406',
        message: 'Not Acceptable',
      }
    }
  },
  // Não é cliente claro móvel
  '51944045066': {
    type: 'error',
    response: {
      statusCode: 422,
      error: {
        code: '422',
        message: 'Unprocessable Entity',
      }
    }
  },
  // Mais de um celular no mesmo CPF
  '78701196014': {
    type: 'error',
    response: {
      statusCode: 400,
      error: {
        code: '400-004',
        message: 'Bad Request',
      }
    }
  },
  // Erro na validação do CPF
  '30389125067': {
    type: 'error',
    response: {
      statusCode: 400,
      error: {
        code: '400-005',
        message: 'Bad Request',
      }
    }
  },
  // Número de celular não é o principal
  '87448761064': {
    type: 'error',
    response: {
      statusCode: 400,
      error: {
        code: '400-006',
        message: 'Bad Request',
      }
    }
  },
  // Plano ativo e cliente não é proprietário
  '45510237015': {
    type: 'success',
    response: {
      data: {
        customerType: 'dependent',
        situation: 'ATIVO',
        planName: 'Plano A',
        planValue: 100.00,
        planType: 'POS_PAGO',
        planId: 123,
        promotionDiscountPlanValue: 0,
        planComponents: 'VOZ,DADOS',
        dependents: [],
        customerName: 'string',
      },
      meta: {
        code: '200',
        message: 'Success'
      },
      code: '200',
      message: 'Success',
      statusCode: 200
    },
  },
  // Plano ativo e cliente é proprietário
  '72795594099': {
    type: 'success',
    response: {
      data: {
        customerType: 'owner',
        situation: 'ATIVO',
        planName: 'Plano B',
        planValue: 150.00,
        planType: 'POS_PAGO',
        planId: 456,
        promotionDiscountPlanValue: 0,
        planComponents: 'VOZ,DADOS,TORPEDOS',
        dependents: [],
        customerName: 'string',
      },
      meta: {
        code: '200',
        message: 'Success'
      },
      code: '200',
      message: 'Success',
      statusCode: 200
    },
  },
  // Plano ATIVO, cliente é o proprietário, não possui dependentes.
  '60135869005': {
    type: 'success',
    response: {
      data: {
        customerType: 'owner',
        situation: 'ATIVO',
        planName: 'Plano B',
        planValue: 150.00,
        planType: 'POS_PAGO',
        planId: 456,
        promotionDiscountPlanValue: 0,
        planComponents: 'VOZ,DADOS,TORPEDOS',
        dependents: [],
        customerName: 'string',
      },
      meta: {
        code: '200',
        message: 'Success'
      },
      code: '200',
      message: 'Success',
      statusCode: 200
    },
  },
  // Plano ativo, cliente é proprietário, não possui dependentes e tem plano com desconto
  '32477771051': {
    type: 'success',
    response: {
      data: {
        customerType: 'owner',
        situation: 'ATIVO',
        planName: 'Plano C',
        planValue: 200.00,
        planType: 'PLANO_COM_DESCONTO',
        planId: 22375,
        promotionDiscountPlanValue: 50.00,
        planComponents: 'VOZ,DADOS,TORPEDOS',
        dependents: [],
        customerName: 'string',
      },
      meta: {
        code: '200',
        message: 'Success'
      },
      code: '200',
      message: 'Success',
      statusCode: 200
    },
  },
  // Plano ativo, cliente é proprietário, não possui dependentes e tem plano de controle
  '73292476003': {
    type: 'success',
    response: {
      data: {
        customerType: 'owner',
        situation: 'ATIVO',
        planName: 'Plano D',
        planValue: 80.00,
        planType: 'CONTROLE',
        planId: 20968,
        promotionDiscountPlanValue: 0,
        planComponents: 'VOZ,DADOS',
        dependents: [],
        customerName: 'string',
      },
      meta: {
        code: '200',
        message: 'Success'
      },
      code: '200',
      message: 'Success',
      statusCode: 200
    },
  },
  // Adicione mais CPFs e dados mockados conforme necessário
};
