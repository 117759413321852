interface IExclusiveCheckoutResponse {
  affiliateIds: string[]
}

export const resolveExclusiveCheckout = (
  story: any
): IExclusiveCheckoutResponse => {
  return {
    affiliateIds: story.data.stories[0].content.affiliateIdsCheckout
  }
}
