import { useAtom } from "jotai"
import { featureFlagAtom } from "../store"
import { featureFlagService } from "../services"

const useFeatureFlag = () => {
  const [featureFlag, setFeatureFlag] = useAtom(featureFlagAtom)

  const getFeatureFlag = async () => {
    try {
      if (featureFlag.data) return
      const data = await featureFlagService.get()
      setFeatureFlag(data)
    } catch (error) {
      console.log("Erro ao buscar feature flag: ", error)
    }
  }

  return {
    getFeatureFlag,
    featureFlag: featureFlag.data,
    setFeatureFlag
  }
}

export default useFeatureFlag
