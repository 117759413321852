import FormState from "../types/FormState";
import {
  FormAction,
  FormActionTypes,
  UpdateCheckboxControlPayload,
  UpdateControlPayload
} from "../types/FormReducer";

/**
 * The reducer function for the form state.
 * @param state - The current state of the form.
 * @param action - The action to be performed on the form state.
 * @returns The updated form state.
 */
export default function FormReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    /**
     * Handles the UPDATE_CONTROL action type by updating the state of a specific form control.
     * @returns The updated form state with the control state updated.
     */
    case FormActionTypes.UPDATE_CONTROL: {
      const payload = action.payload as UpdateControlPayload;
      return validateFormState({
        controls: {
          ...state.controls,
          [payload.control.name]: {
            ...state.controls[payload.control.name],
            ...payload.control.state
          }
        },
        valid: false,
      });
    }

    /**
     * Handles the UPDATE_CHECKBOX_CONTROL action type by updating the state of the checkbox control.
     * @returns The updated form state after validating the changes.
     */
    case FormActionTypes.UPDATE_CHECKBOX_CONTROL: {
      const payload = action.payload as UpdateCheckboxControlPayload;
      return validateFormState({
        controls: {
          ...state.controls,
          [payload.control.name]: {
            ...state.controls[payload.control.name],
            ...payload.control.state,
          }
        },
        valid: false
      });
    }

    /**
     * Updates the state to enable previously disabled form fields.
     * @returns The updated state with enabled form fields.
     */
    case FormActionTypes.ENABLE_DISABLED_FIELDS: {
      return ({
        ...state,
        controls: {
          ...state.controls,
          cpf: {
            ...state.controls.cpf,
            loading: false,
            disabled: false,
          },
          celular: {
            ...state.controls.celular,
            disabled: false,
          },
          cep: {
            ...state.controls.cep,
            disabled: false,
          },
          numero: {
            ...state.controls.numero,
            disabled: state.controls.checkbox.checked ? true : false,
          },
          checkbox: {
            ...state.controls.checkbox,
            disabled: false,
          },
        }
      });
    }

    case FormActionTypes.DISABLE_ALL_FIELDS: {
      return {
        ...state,
        controls: {
          ...state.controls,
          cpf: {
            ...state.controls.cpf,
          },
          celular: {
            ...state.controls.celular,
            disabled: true,
          },
          cep: {
            ...state.controls.cep,
            disabled: true,
          },
          numero: {
            ...state.controls.numero,
            disabled: true,
          },
          checkbox: {
            ...state.controls.checkbox,
            disabled: true,
          },
        },
      };
    }

    default:
      return state;
  }
}

/**
 * Validates the state of a form by checking if all required controls are valid.
 * @param formState - The current state of the form.
 * @returns The updated form state with a valid property indicating if the form is valid or not.
 */
const validateFormState = (formState: FormState): FormState => {
  const controllerValues = Object.values(formState.controls);

  for (let i = 0; i < controllerValues.length; i++) {
    if (!controllerValues[i].valid && controllerValues[i].required) {
      return { ...formState, valid: false };
    }
  }

  return { ...formState, valid: true };
}
