import CroService from "../services/CroService"

type HandlerTaggingT = {
  eventAction: string
  eventLabel: string
  event?: string
}

const useTagging = (eventCategory: string) => {
  const handleTagging = async ({
    event = "event",
    eventAction,
    eventLabel
  }: HandlerTaggingT) => {
    CroService.postDataLayer({
      eventCategory,
      eventAction,
      eventLabel,
      event
    })
  }

  return {
    handleTagging
  }
}

export default useTagging
