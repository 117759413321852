const regex = /\D/g

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: {
    message: "O campo deve conter um número de celular válido.",
    valid: false,
    regex
  }
}

export const phone = (value) => {
  const phone = value?.replace(regex, "")
  if (!phone) return responses.empty
  const validation = phone?.length === 11 && phone.charAt(2) === "9"
  if (!validation) return responses.invalid
  return responses.valid
}
