import { useAtom } from "jotai"
import { generalRulesService } from "../services"
import { storyblokAtom } from "../store"
import { storyblokParse } from "../utils"

const useGeneralRules = () => {
  const [storyblok, setStoryblok] = useAtom(storyblokAtom)

  const getGeneralRules = async () => {
    try {
      if (storyblok?.data?.generalRules) return
      const json = await generalRulesService.get()
      const data = storyblokParse({ data: json.data })
      const response = { generalRules: json.data }
      const storyblokData = { data, response }
      setStoryblok(storyblokData)
    } catch (err) {
      console.log("Erro ao buscar regras gerais: ", err)
    }
  }

  return {
    getGeneralRules,
    storyblok: storyblok.data?.generalRules,
    response: storyblok.response?.generalRules,
    setStoryblok
  }
}

export default useGeneralRules
