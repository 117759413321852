import axios from "axios";
import { FilterQueryModel, ResolveRelationsModel, StoryBlok } from "../models/iStoryBlok";

export class StoryBlockService {
    public spaceDir = 'residencial/prospect';
    public storiesPerPage = '25';
    public storyBlok: StoryBlok
    
    constructor() { return }

    async getConfig(fileSlug: string, filterQuery?: FilterQueryModel, resolveRelations?: ResolveRelationsModel, page?: string) {
        let storyBlokData = {};
        const envData = process.env.storyBlokApi as unknown as StoryBlok;
        const params = {
            page: page || '1',
            per_page: this.storiesPerPage,
            starts_with: `${this.spaceDir}/${fileSlug}`,
            token: envData.token,
            version: envData.version
        }

        if (filterQuery) {
            params[`filter_query[${filterQuery.attribute}][${filterQuery.operation}]`] = filterQuery.value;
        }

        if (resolveRelations) {
            params['resolve_relations'] = `${resolveRelations.component}.${resolveRelations.field}`
        }

        await axios.get(envData.api, { params }).then((response) => {
                storyBlokData = response
            }).catch((error) => console.warn(error));

        return storyBlokData;
    }

    async getBannersPromotionalClienteMovelInfo(technology: string, custumer_plan_category: string) {
        const envData = process.env.storyBlokApi as unknown as StoryBlok;
        const params = {
            'filter_query[technology][any_in_array]': technology,
            'filter_query[custumer_plan_category][any_in_array]': custumer_plan_category,
            starts_with: 'residencial/prospect/cliente-claro-movel/promotional-banners/',
            token: envData.token,
            version: envData.version
        }
        try {
            const response = await axios.get(envData.api, { params })
            return response;
        } catch (error) {
            console.warn(error);
        }
    }
}

export default new StoryBlockService();