import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { IMaskInput } from "react-imask"
import styles from "./styles.module.scss"

type MaskKey = "cep" | "phone" | "cpf" | "birthdate" | "none"

interface ICustomInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  htmlFor?: string
  label?: string
  type?: "number" | "text"
  value: string
  defaultValue?: string
  hasError?: boolean
  errorMessage?: string
  mask?: MaskKey
  unmask?: boolean
  ref?: React.RefObject<HTMLInputElement>
  tooltipText?: string
  checkmark?: 'none' | 'success' | 'error'
}

const CustomInput: React.ForwardRefExoticComponent<Omit<ICustomInputProps, "ref"> & React.RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, ICustomInputProps>(({
  htmlFor,
  name,
  label,
  value,
  defaultValue,
  hasError,
  errorMessage,
  type = "text",
  mask = "none",
  unmask = false,
  onFocus,
  onBlur,
  onChange,
  tooltipText,
  checkmark = 'none',
  ...props
}, ref) => {
  const [touched, setTouched] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)


  const checkMarkSetup = {
    success: <span title="Preenchimento correto" className={`mdn-Icon-check mdn-Icon--sm ${styles.checkmark}`} />,
    error: <span title="Preenchimento incorreto" className={`mdn-Icon-fechar mdn-Icon--sm ${styles.checkmarkInvalid}`} />,
    none: null
  }

  const handleInputAccept = (value: string) => {
    if (!touched) return

    onChange?.({
      target: {
        name,
        value
      }
    } as React.ChangeEvent<HTMLInputElement>)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.({
      target: {
        name,
        value: event.target.value
      }
    } as React.ChangeEvent<HTMLInputElement>)
  }

  const handleInputFocus = (_event: React.FocusEvent<HTMLInputElement>) => {
    setTouched(true)
    onFocus?.({
      target: {
        value,
        name
      }
    } as React.FocusEvent<HTMLInputElement>)
    setTooltipVisible(true)
  }

  const handleInputBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.({
      target: {
        value,
        name
      }
    } as React.FocusEvent<HTMLInputElement>)
  }

  const handleInputMouseEnter = () => {
    if (tooltipVisible) return
    setTooltipVisible(true)
  }

  const handleInputMouseLeave = () => {
    if (!tooltipVisible) return
    setTooltipVisible(false)
  }

  const maskSetup = {
    cep: [
      { mask: "00000 - 000", lazy: true, placeholderChar: "_", maxLength: 8 }
    ],
    phone: [
      {
        mask: "(00) 0 0000 - 0000",
        lazy: true,
        placeholderChar: "_",
        maxLength: 11
      }
    ],
    cpf: [
      {
        mask: "000.000.000-00",
        lazy: true,
        placeholderChar: "_",
        maxLength: 14
      }
    ],
    birthdate: [
      { mask: "00/00/0000", lazy: true, placeholderChar: "_", maxLength: 10 }
    ],
    none: undefined
  }[mask as MaskKey]

  const activeField = value || touched
  const hasMask = maskSetup !== undefined
  const commonProps = {
    className: `mdn-Input-field ${checkmark === 'success' ? styles.inputValid : ''}`,
    type,
    value,
    onFocus: handleInputFocus,
    onBlur: handleInputBlur,
    onMouseEnter: handleInputMouseEnter,
    onMouseLeave: handleInputMouseLeave,
    name,
    ref: inputRef,
    ...props
  }

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        if (inputRef.current === document.activeElement) return
        inputRef.current?.focus()
      },
      id: inputRef.current?.id,
    } as HTMLInputElement
  }, [inputRef])

  useEffect(() => {
    if (tooltipVisible) {
      const timeout = setTimeout(() => {
        setTooltipVisible(false)
      }, 2000)

      return () => clearTimeout(timeout)
    }
  }, [tooltipVisible])

  return (
    <>
      <div
        className={`gtm-element-event mdn-Input ${activeField && "mdn-Input--activatedField"} ${errorMessage ? "mdn-Input--attentionField"
          : ''}`}
        style={{ position: "relative" }}
      >
        {hasMask ? (
          <IMaskInput
            onAccept={handleInputAccept}
            unmask={!unmask}
            mask={maskSetup}
            {...commonProps}
          />
        ) : (
          <input onChange={handleInputChange} {...commonProps} />
        )}
        <label htmlFor={name} className={`mdn-Input-label`}>
          {label}
        </label>
        {errorMessage && (
          <small className="mdn-Input-errorFeedback">{errorMessage}</small>
        )}
        {checkMarkSetup[checkmark]}
        {tooltipText && tooltipVisible && (
          <div className={styles.tooltip}>
            <p className="mdn-Text mdn-Text--caption mdn-Text--inverse">
              {tooltipText}
            </p>
          </div>
        )}
      </div>
    </>
  )
})

export default CustomInput
