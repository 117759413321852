import React, { FC } from "react"
import * as M from "mondrian-react"

interface ISlideProps {
  children: React.ReactNode
  banner?: boolean
  loop?: boolean
  autoplay?: boolean
  slidesPerView?: number
  className?: string
}

const Slide: FC<ISlideProps> = ({ children, ...rest }) => {
  return <M.Swiper {...rest}>{children}</M.Swiper>
}

export default Slide
