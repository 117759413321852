import React from 'react';
import FormContext from './context/FormContext';
import { FormActionTypes } from './types/FormReducer';
import JaSouClienteContext from '../../../../../../contexts/jornada-ja-sou-cliente';

const Form: React.FC<React.HTMLProps<HTMLFormElement>> = (props) => {
  const { dispatchFormState } = React.useContext(FormContext);
  const { outroEndereco } = React.useContext(JaSouClienteContext);

  /**
   * useEffect hook that enables disabled fields in the form state if outroEndereco is truthy.
   * @param dispatchFormState - function to dispatch form state updates
   * @param outroEndereco - boolean value indicating whether or not to enable disabled fields
   */
  React.useEffect(() => {
    if (outroEndereco) {
      dispatchFormState({
        type: FormActionTypes.ENABLE_DISABLED_FIELDS,
      });
    }
  }, [dispatchFormState, outroEndereco]);

  return (
    <form {...props}>
      <div className="mdn-Row">{props.children}</div>
    </form>
  );
}

export default Form;
