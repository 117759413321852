import axios from "axios";
import { Technology, ViabilityResponse } from "../types/ViabilityResponse";

class ViabilityService {
  private readonly API_URL = "https://api.amxrest.net/viability";
  public viabilityResponse: ViabilityResponse | undefined = undefined;

  constructor() { return }

  /**
   * Sends a request to the Viability API to check the viability of a given address.
   * @param {string} cep - The CEP (postal code) of the address.
   * @param {string} numero - The number of the address.
   * @returns {Promise<ViabilityResponse>} A promise that resolves with the response
   * object returned by the Viability API.
   */
  public async consultar(cep: string, numero: string): Promise<ViabilityResponse> {
    return new Promise<ViabilityResponse>((resolve, reject) => {
      axios.get(`${this.API_URL}/${cep}/${numero}`)
        .then(response => {
          this.viabilityResponse = response.data;
          resolve(this.viabilityResponse);
        })
        .catch(error => { reject(error) });
    });
  }

  /**
   * Returns an array of technologies available for the address checked by the Viability API.
   * @returns {Technology[]} An array of technologies available for the address.
   */
  public getTechnologies(): Technology[] {
    return this.viabilityResponse.data.technologies;
  }

  /**
   * Returns the CEP (postal code) of the address checked by the Viability API.
   * @returns {number} The CEP (postal code) of the address.
   */
  public getCEP(): number {
    return this.viabilityResponse.data.cep;
  }

  /**
   * Returns the logradouro (street name) of the address checked by the Viability API.
   * @returns {string} The logradouro (street name) of the address.
   */
  public getLogradouro(): string {
    return this.viabilityResponse.data.logradouro;
  }

  /**
   * Returns the number of the address checked by the Viability API.
   * @returns {string} The number of the address.
   */
  public getNumber(): string {
    return this.viabilityResponse.data.number;
  }

  /**
   * Returns the cidade (city) of the address checked by the Viability API.
   * @returns {string} The cidade (city) of the address.
   */
  public getCidade(): string {
    return this.viabilityResponse.data.cidade;
  }

  /**
   * Returns the bairro (neighborhood) of the address checked by the Viability API.
   * @returns {string} The bairro (neighborhood) of the address.
   */
  public getBairro(): string {
    return this.viabilityResponse.data.bairro;
  }

  /**
   * Returns the UF (state) of the address checked by the Viability API.
   * @returns {string} The UF (state) of the address.
   */
  public getUF(): string {
    return this.viabilityResponse.data.uf;
  }

  /**
   * Determines if a list of technologies includes HFC (Hybrid Fiber Coaxial) technology.
   * @param techs - An array of Technology objects to check.
   * @returns A boolean indicating whether HFC technology is present in the array.
   */
  resolveIsHFC(techs: Technology[]): boolean {
    return techs.some(obj => obj.name === 'Cable' && !obj.gpon);
  }
}

export default new ViabilityService();
