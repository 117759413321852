import React, { forwardRef, useEffect, useRef, useState } from "react"
import CroService from "../../services/CroService"
import ModalService from "../../services/ModalService"
import { ModalSettings } from "../../types/ModalSettings"
import styles from "./ModalComponent.module.scss"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ModalComponent = forwardRef((modalProps: ModalSettings, ref) => {
  const modalBodyRef = useRef(null);
  
  const {
    component: RenderInner,
    componentProps,
    title,
    taguear,
    size,
    showHeader,
    bannerMode,
    bannerPromocional } = modalProps
  const [overlayClicked, setOverlayClicked] = useState(false);
    
  /**
   * Runs when the component mounts and unmounts.
   * If taguear is true, sends a data layer event to CroService when
   * the modal opens and closes.
   */
  useEffect(() => {
    if (taguear) {
      const data = modalProps.tags.abertura;
      CroService.postDataLayer(data);
    }

    return () => {
      if (taguear) {
        const data = modalProps.tags.fechamento;
        CroService.postDataLayer(data)
      }
    }
  }, [modalProps.tags?.abertura, modalProps.tags?.fechamento, taguear])

  /**
   * Runs when the component mounts and unmounts.
   * Adds an event listener for the 'Escape' key to close the modal.
   */
  useEffect(() => {
    function keyDownHandler(event) {
      if (event.key === "Escape") {
        event.preventDefault()
        ModalService.close()
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [])

  useEffect(() => {
    if (overlayClicked) {
      handleModalClosing()
    }
  }, [overlayClicked])

  /**
   * Handles clicks on the modal overlay.
   * Stops the event from propagating and closes the modal if the click
   * was outside the modal content.
   * @param e - The click event.
   */
  function handleOverlayClick(e) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    if (e.target === e.currentTarget) {
      setOverlayClicked(true)
    }
  }

  /**
   * This function handles the closing of a modal by calling the close
   * method of the ModalService.
   * @returns void
   */
  function handleModalClosing(): void {
    ModalService.close()
  }

  /**
   * useEffect hook that disables overflowY on the modal body if
   * modalProps.disableOverflowY is true.
   * This is done to prevent scrolling on the body while the modal
   * is open.
   */
  useEffect(() => {
    const disableOverflowY = () => {
      if (modalProps.disableOverflowY && modalBodyRef.current) {
        modalBodyRef.current.style.overflowY = 'hidden';
      }
    };

    disableOverflowY();
  }, [modalProps.disableOverflowY]);

  /**
   * useEffect hook that disables overflowX on the modal body if
   * modalProps.disableOverflowX is true.
   * This is done to prevent scrolling on the body while the modal
   * is open.
   */
  useEffect(() => {
    const disableOverflowX = () => {
      if (modalProps.disableOverflowX && modalBodyRef.current) {
        modalBodyRef.current.style.overflowX = 'hidden';
      }
    };

    disableOverflowX();
  }, [modalProps.disableOverflowX]);

  return (
    <div className={`mdn-Modal mdn-Modal--${size} mdn-isOpen`}
      onClick={handleOverlayClick}>
      <div className={`mdn-Modal-content ${bannerMode && styles.mdnContentInBannerMode} ${bannerPromocional && styles.bannerPromocional}`}>
        {showHeader &&
          <>
            <div className="mdn-Modal-header">
              <h1 className={`mdn-Heading mdn-Heading--md ${styles.mdnHeading}`}>
                {title}
              </h1>
              <button className={`mdn-Button-header-close mdn-Button mdn-Button--icon mdn-Button--secondary ${styles.closeBtn}`}
                aria-label="Close modal"
                onClick={handleModalClosing}>
                <i className="mdn-Icon-fechar mdn-Icon--sm"></i>
              </button>
            </div>
            <div className="mdn-Divider mdn-Divider--default"></div>
          </>
        }

        <div ref={modalBodyRef} className={`mdn-Modal-body ${bannerMode && styles.mdnBodyInBannerMode}`}>
          {RenderInner && <RenderInner inModal={true} {...componentProps} overlayClicked={overlayClicked} />}
        </div>
      </div>
    </div>
  )
}
);

ModalComponent.displayName = 'ModalComponent';
