

const regex = /\D/g

const responses = {
  valid: { message: "", valid: true, regex },
  empty: { message: "Preenchimento obrigatório.", valid: false, regex },
  invalid: { message: "O campo deve conter um CEP válido.", valid: false, regex }
}

export const cep = (value) => {
  const cep = value.replace(/\D/g, "")
  if (!cep) return responses.empty
  const cepRegex = /[0-9]{5}[\d]{3}/
  const validation = cepRegex.test(cep)
  if (!validation) return responses.invalid
  return responses.valid
}
