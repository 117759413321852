import axios from "axios";
import { DeParaItem } from "../types/JornadaJaSouCliente/DeParaItem";

class BucketS3Service {
  private readonly BUCKET_S3_URL = 'https://assets.clarobrasil.mobi/ecommerce-config/ecommerce-responsivo/config';

  constructor() { return }

  buscarDeParaIdentificacaoMovel(): Promise<DeParaItem[]> {
    return new Promise<DeParaItem[]>((resolve, reject) => {
      axios.get(`${this.BUCKET_S3_URL}/mobile-customer-identification/listUpdatedPlans.json`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    })
  }
}

export default new BucketS3Service();
