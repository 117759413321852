import React, { useEffect } from 'react';
import ModalService from '../../../../services/ModalService';

import Image from 'next/image';
import { useTagging } from '../../../../hooks';
import { Slide } from '../../../../shared';
import styles from './BannerPromocional.module.scss';
import { parseQueryString } from '../../../../utils';
interface ComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storyBlok: any[],
  celularId?: number,
  cep: string,
  numero: string,
  tech: string,
  overlayClicked: boolean
}

const BannerPromocional: React.FC<ComponentProps> = ({ storyBlok, celularId, cep, numero, tech, overlayClicked }) => {

  const { handleTagging: handleModalTagging } = useTagging('planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios')
  const [imgBannersPromo, setImgBannersPromoState] = React.useState([])
  const [imageDimensions, setImageDimensions] = React.useState({ width: 343, height: 549 })

  const handleOnClickBanner = (index: number): void => {
    const mountedQueryString = parseQueryString({ key: storyBlok[index].idProducts })
    const offerName = storyBlok[index].slug;
    const eventLabel = `contratar:${offerName}:${tech}`

    handleModalTagging({
      eventAction: 'clique:modal:cliente-claro-movel-beneficios-banner',
      eventLabel
    });

    sessionStorage.setItem('originId', 'cliente_movel-cobertura')

    window.location.href = `${process.env.prospectUrl}/checkout/dados-pessoais?cep=${cep}&number=${numero}&${mountedQueryString}`;
  }

  const handleButtonFecharClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    const eventLabel = `fechar:banner-promocional:${tech}`
    handleModalTagging({
      eventAction: 'clique:botao',
      eventLabel
    });

    ModalService.close()
    redirectCepNumber()
  }

  const handleOverlayClick = (): void => {
    if (!overlayClicked) return
    const eventLabel = `fechar:banner-promocional:${tech}`
    handleModalTagging({
      eventAction: 'clique:fora',
      eventLabel
    });
    redirectCepNumber()
  }

  const redirectCepNumber = (): void => {
    if (!!cep && !!numero) {
      window.location.href = `${process.env.prospectUrl}/monte-sua-combinacao?cep=${cep}&number=${numero}`;
    }
  }

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setImageDimensions({ width: 787, height: 667 })
      setImgBannersPromoState(storyBlok.map(story => story.banner_desktop.filename))
    } else {
      setImageDimensions({ width: 343, height: 549 })
      setImgBannersPromoState(storyBlok.map(story => story.banner_mobile.filename))
    }
  }, [storyBlok, window.innerWidth])

  useEffect(() => {
    const eventLabel = `cliente-claro-movel-beneficios-banner:${tech}`
    handleModalTagging({
      eventAction: 'modal:abriu',
      eventLabel
    })
  }, [])

  useEffect(() => {
    handleOverlayClick()
  }, [overlayClicked])

  return (
    <div id="super-oferta" className={styles.superOferta}>
      <Slide banner loop={true} className={styles.swiperBanner} slidesPerView={1}>
        {imgBannersPromo.map((imgBanner, i) => (
          <div className={`mdn-Banner-item ${styles.bannerItem}`} key={i} onClick={() => handleOnClickBanner(i)}>
            <Image
              src={imgBanner}
              alt={`Banner Promocional ${i + 1}`}
              width={imageDimensions.width}
              height={imageDimensions.height}
              priority
            />
          </div>
        ))}
      </Slide>
      <button id="button-fechar-modal"
        className={styles.buttonFecharModal}
        onClick={handleButtonFecharClick}>
      </button>
    </div>
  );
}

export default BannerPromocional;


