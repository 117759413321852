import { useState } from "react";
import ViabilityService from "../services/ViabilityService";
import { ViabilityResponse } from "../types/ViabilityResponse";
import axios from "axios";

const useViability = () => {
  const [viabilityResponse, setViabilityResponse] = useState<ViabilityResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isHFC, setIsHFC] = useState<boolean | undefined>(undefined);

  /**
   * A function that checks the viability of an address given a zip code and street number.
   *
   * @param cep The zip code of the address to check.
   * @param numero The street number of the address to check.
   */
  const consultarViabilidade = async (cep: string, numero: string) => {
    setIsLoading(true);
    setError(null);

    try {
      var jwt = require('jsonwebtoken');
      const response = await axios.get(process.env.availableTechnologies, { headers: {'cep': cep, 'number': numero} })
      const responseDecoded = jwt.decode(response.data)
      ViabilityService.viabilityResponse = responseDecoded;
      setViabilityResponse(responseDecoded.data);
      const technologies = responseDecoded.data.technologies;
      const hasHFC = ViabilityService.resolveIsHFC(technologies);
      setIsHFC(hasHFC);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    viabilityResponse,
    isLoading,
    error,
    isHFC,
    consultarViabilidade,
  };
};

export default useViability;
