import React, { ChangeEvent, FocusEvent } from 'react';
import { Input } from 'mondrian-react';
import ReactLoading from 'react-loading';

import Utils from '../../../../../../../services/UtilsService';

import FormContext from '../context/FormContext';
import { FormControl } from '../types/FormControl';
import { FormActionTypes } from '../types/FormReducer';
import { SessionStorageKeys } from '../../../../../../../types/SessionStorageKeys';

import styles from './CpfControl.module.scss';
import UtilsService from '../../../../../../../services/UtilsService';
import CroService from '../../../../../../../services/CroService';
import JaSouClienteContext from '../../../../../../../contexts/jornada-ja-sou-cliente';

const CpfControl: React.FC = () => {
  const { formState, dispatchFormState } = React.useContext(FormContext);
  const context = React.useContext(JaSouClienteContext);
  const CONTROL_NAME = 'cpf';
  const CPF_LENGTH = 11;

  const handleOnFocus = (): void => {
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            touched: true,
            focused: true,
          }
        }
      }
    });
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = Utils.limparCpf(e.target.value);
    saveToSessionStorage(value);
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            value: value,
          }
        }
      }
    });
  }

  const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>): void => {
    const value = Utils.limparCpf(e.target.value);
    const result = Utils.validarCpf(value);
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            valid: result.valid,
            error: !result.valid ? result.errorMessage : undefined,
            focused: false,
          }
        }
      }
    });

    // Verifica se o valor do celular possui algum caractere
    if (value.length === 0) {
      // Dispara o evento de rastreamento para o serviço CroService
      CroService.postDataLayer({
        event: 'event',
        eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
        eventAction: 'interacao:campo',
        eventLabel: 'preencheu:cpf-titular'
      });
    }
  }

  const saveToSessionStorage = (value: string) => {
    const key = SessionStorageKeys.CPF_JA_SOU_CLIENTE;
    window.sessionStorage.setItem(key, value);
    window.sessionStorage.setItem('claro-movel-cpf', value);
  };

  React.useEffect(() => {
    // Verifica se o comprimento do CPF é diferente do valor esperado
    if (formState.controls.cpf.value.length !== CPF_LENGTH) {
      // Atualiza o estado do controle do formulário para CPF
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              valid: undefined, // Define como undefined para indicar que CPF não está pronto para validação
              error: undefined // Limpa a mensagem de erro
            }
          }
        }
      });
    }
  }, [dispatchFormState, formState.controls.cpf.value]);

  React.useEffect(() => {
    // Recupera o CPF armazenado na sessionStorage
    const storedCpf = sessionStorage.getItem(SessionStorageKeys.CPF_JA_SOU_CLIENTE);

    // Se um CPF estiver armazenado, atualiza o controle do formulário com o valor do CPF
    if (storedCpf) {
      updateControlWithCpf(storedCpf);
    }

    // Função auxiliar para atualizar o controle do formulário com o valor do CPF
    function updateControlWithCpf(cpf: string) {
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: cpf,
            }
          }
        }
      });
    }
  }, [dispatchFormState]);

  React.useEffect(() => {
    // Obtém o valor atual do campo CPF do estado do formulário
    const cpfValue = formState.controls.cpf.value;
    // Obtém o comprimento atual do valor do CPF
    const cpfLength = cpfValue.length;

    // Verifica se o CPF possui o comprimento esperado
    if (cpfLength === CPF_LENGTH) {
      // Lida com um CPF válido
      handleValidCpf();
    } else {
      // Lida com um CPF inválido
      handleInvalidCpf();
    }

    // Lida com um CPF válido
    function handleValidCpf() {
      // Valida o CPF utilizando o serviço UtilsService
      const result = UtilsService.validarCpf(cpfValue);

      // Define o estado do controle do formulário com base no resultado da validação
      const controlState = {
        name: CONTROL_NAME,
        state: {
          valid: result.valid,
        } as FormControl
      };

      // Se o CPF for inválido, adiciona a mensagem de erro ao estado do controle
      if (!result.valid) {
        controlState.state.error = result.errorMessage;
      }

      // Atualiza o estado do controle do formulário utilizando o dispatchFormState
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: controlState
        }
      });
    }

    // Lida com um CPF inválido
    function handleInvalidCpf() {
      // Desabilita todos os campos do formulário
      dispatchFormState({ type: FormActionTypes.DISABLE_ALL_FIELDS });

      // Verifica se o campo CPF já foi tocado
      if (formState.controls.cpf.touched) {
        // Limpa as chaves de sessionStorage relacionadas
        resetSessionStorageKeys();
      }

      // Define o estado do controle do formulário como inválido
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              valid: undefined,
              error: undefined
            }
          }
        }
      });
    }

    // Limpa as chaves de sessionStorage relacionadas quando o CPF é inválido
    function resetSessionStorageKeys() {
      context.setOutroEndereco(undefined);
      sessionStorage.removeItem(SessionStorageKeys.OUTRO_ENDERECO_JA_SOU_CLIENTE);
      context.setResidentialCustomer(undefined);
      sessionStorage.removeItem(SessionStorageKeys.RESIDENTIAL_CUSTOMER_JA_SOU_CLIENTE);
      sessionStorage.removeItem('cliente-claro-movel');
    }
  }, [context, dispatchFormState, formState.controls.cpf.touched, formState.controls.cpf.value]);

  return (
    <div className="mdn-Col-xs-12">
      <div className={styles.inputContainer}>
        <Input
          id="cpf"
          name="cpf"
          cpf
          required={formState.controls.cpf.required}
          hasError={formState.controls.cpf.valid === false}
          errorMessage={formState.controls.cpf.error}
          disabled={formState.controls.cpf.disabled}
          value={formState.controls.cpf.value}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onChange={handleOnChange}
        >
          CPF
        </Input>

        {formState.controls.cpf.loading &&
          <ReactLoading className={styles.loading} type="spin" color="#000"
            height={20} width={20} />
        }

        {!formState.controls.cpf.loading && formState.controls.cpf.valid &&
          <span className={`mdn-Icon-check-circulo mdn-Icon--md ${styles.mdnIcon}`}></span>
        }
      </div>
    </div>
  );
}

export default CpfControl;
