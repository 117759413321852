import React, { ChangeEvent, FocusEvent } from 'react';

import { SessionStorageKeys } from '../../../../../../../types/SessionStorageKeys';
import FormContext from '../context/FormContext';
import { FormActionTypes } from '../types/FormReducer';

import CroService from '../../../../../../../services/CroService';
import Utils from '../../../../../../../services/UtilsService';
import { CustomInput } from '../../../../../../../shared';

const NumeroControl: React.FC = () => {
  const { formState, dispatchFormState } = React.useContext(FormContext);

  const CONTROL_NAME = 'numero';

  const handleOnFocus = (): void => {
    // Atualiza o estado do controle para indicar que ele recebeu foco
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            touched: true,
            focused: true,
          }
        }
      }
    });
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    if (CONTROL_NAME === 'numero' && value.startsWith('0')) return

    saveToSessionStorage(value);
    // Atualiza o estado do controle com o novo valor
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            value,
          }
        }
      }
    });
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement, Element>): void => {
    const { value } = e.target
    // Valida o número de residência utilizando Utils.validarNumeroResidencia
    const result = Utils.validarNumeroResidencia(value);

    // Atualiza o estado do controle com o resultado da validação e outras propriedades
    dispatchFormState({
      type: FormActionTypes.UPDATE_CONTROL,
      payload: {
        control: {
          name: CONTROL_NAME,
          state: {
            value,
            valid: result.valid,
            error: !result.valid ? result.errorMessage : undefined,
            focused: false,
          }
        }
      }
    });

    if (value.length > 0) {
      // Envia um evento para o serviço CroService indicando que o campo foi preenchido
      CroService.postDataLayer({
        event: 'event',
        eventCategory: 'planos-claro-res:cobertura:modal-cliente-claro-movel-beneficios',
        eventAction: 'interacao:campo',
        eventLabel: 'preencheu:numero'
      });
    }
  }

  const saveToSessionStorage = (value: string) => {
    const key = SessionStorageKeys.NUMERO_JA_SOU_CLIENTE;

    // Salva o valor no sessionStorage
    window.sessionStorage.setItem(key, value);
  };

  React.useEffect(() => {
    // Atualiza o estado do controle com base na propriedade checked do controle checkbox
    if (formState.controls.checkbox.touched) {
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: '',
              valid: undefined,
              error: undefined,
              disabled: formState.controls.checkbox.checked,
              required: !formState.controls.checkbox.checked
            }
          }
        }
      });

      if (formState.controls.checkbox.checked) {
        saveToSessionStorage('0');
      }
    }

  }, [dispatchFormState, formState.controls.checkbox.checked, formState.controls.checkbox.touched]);

  React.useEffect(() => {
    if (formState.controls.numero.value.length > 0) {
      const value = formState.controls.numero.value;

      // Valida o número de residência utilizando Utils.validarNumeroResidencia
      const result = Utils.validarNumeroResidencia(value);

      const controlState = {
        valid: result.valid,
        error: result.valid ? undefined : result.errorMessage
      };

      // Atualiza o estado do controle com base na validação do número de residência
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: controlState
          }
        }
      });
    } else {
      // O valor do número de residência está vazio, limpa o estado do controle
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: '',
              valid: undefined,
              error: undefined
            }
          }
        }
      });
    }
  }, [dispatchFormState, formState.controls.numero.value]);

  React.useEffect(() => {
    const value = sessionStorage.getItem(SessionStorageKeys.NUMERO_JA_SOU_CLIENTE);

    if (value) {
      // Atualiza o estado do controle com o valor armazenado no sessionStorage
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: value,
            }
          }
        }
      });
    }
  }, [dispatchFormState]);

  React.useEffect(() => {
    if (formState.controls.checkbox.checked) {
      dispatchFormState({
        type: FormActionTypes.UPDATE_CONTROL,
        payload: {
          control: {
            name: CONTROL_NAME,
            state: {
              value: '0',
            }
          }
        }
      });
    }
  }, [dispatchFormState, formState.controls.checkbox.checked]);

  return (
    <div className="mdn-Col-xs-12 mdn-Col-sm-5">
      <CustomInput
        id="numero"
        name="numero"
        type='number'
        value={formState.controls.numero.value}
        required={formState.controls.numero.required}
        disabled={formState.controls.numero.disabled}
        hasError={!formState.controls.numero.disabled && formState.controls.numero.valid === false}
        errorMessage={formState.controls.numero.error}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        label='Número*'
      />
    </div>
  );
}

export default NumeroControl;
